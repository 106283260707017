import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NonregisterPd25RoutingModule } from './nonregister-pd25-routing.module';
import { AddPd25FormComponent } from './add-pd25-form/add-pd25-form/add-pd25-form.component';
import { ViewPd25FormComponent } from './view-pd25-form/view-pd25-form/view-pd25-form.component';
import { SharedModule } from '@app/theme/shared/shared.module';
import { ListPd25FormComponent } from './list-pd25-form.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [AddPd25FormComponent, ViewPd25FormComponent,ListPd25FormComponent],
  imports: [
    CommonModule,
    SharedModule,
    NonregisterPd25RoutingModule,
     NgMultiSelectDropDownModule.forRoot()

  ]
})
export class NonregisterPd25Module { }
