export class IndentrequestIndustryOutsideUpconfig {

    // public static titleSection = {
    //   list: {
    //       title: 'master.district.list',
    //       button: {
    //           text: 'master.district.adddistrict',
    //           url: '/location/district/add'
    //       }
    //   },
    //   add: {
    //       title: 'master.district.list'
    //   },
    //   edit: {
    //       title: 'master.district.list'
    //   },
    //   view: {
    //       title: 'master.district.list'
    //   }
    // };

    public static breadcrumbs = [
        {
            text: 'language.sugarmill',
            url: '/sugarmill/indent-acceptance-outside-up/list',
            hasHref: true
        },
        {
            text: 'language.indentacceptance',
            url: '/sugarmill/indent-acceptance-outside-up/list',
            hasHref: true
        }
    ];

    public static columns = [
        {
            name: 'id',
            displayName: 'S.No',
            type: 'number',
        },
        {
            name: "indentDate",
            displayName: "Request Date",
            type: "date",
            dateFormat: "dd/MM/yyyy HH:mm:ss",
        },
        // {
        //     name: 'allotmentNumberValue',
        //     displayName: 'indentRequest.list.aId',
        //     type: 'string',
        // },
        {
            name: 'applicationNumber',
            displayName: 'indentRequest.list.iid',
            type: 'string',
        },
        {
            name: 'sugarMillNameValue',
            displayName: 'SugarMill/Khandsari Name',
            type: 'string',
        },
        {
            name: 'molassesGradeValue',
            displayName: 'indentRequest.list.mGrade',
            type: 'string',
        },
        {
            name: 'indentedQuantity',
            displayName: 'indentRequest.list.quantity',
            type: 'string',
        },
        {
            name: "stageDesc",
            displayName: 'Currently With',
            displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
            type: 'objstring'
        },
        {
            name: "valdityStatus",
            displayName: 'Validity Ext. Status',
            displayFcn: (item: any) => (item.valdityStatus) ? item.valdityStatus : '-',
            type: 'objstring'
        },
        {
            name: 'statusDesc',
            displayName: 'Status',
            type: 'options',
            badgeColors: {
                'Draft': 'badge-primary',
                'In Progress': 'badge-secondary',
                'Approved': 'badge-success',
                'Submitted': 'badge-info',
                'SUBMITTED': 'badge-info',
                'Not Available': 'badge-danger',
                'Clarification': 'badge-warning',
                'Rejected': 'badge-danger',
                'Cancelled': 'badge-danger',
                'Validity Extention Rejected': 'badge-danger',
                'Validity Expired': 'badge-danger',
            },
        },
    ];
}