
const pdParfix = "PD";
const parentunitParfix = "PARENT_UNIT";
const parentBrewery = "BREWERY";

const indParfix = "ID";
const mixedParfix = "MIXED";
const vintineryParfix = "VINTNERY"


export class LocalUrl {

  // Pd Section config
  public static pd = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_BRAND_LABEL',
  };
  public static pdexportspiritpd18 = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_EXPORTSPIRIT_PD18_REG',
  };
  public static mixedexportspiritpd18 = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_EXPORTSPIRIT_PD18_REG',
  };
  public static indexportspiritpd18 = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_EXPORTSPIRIT_PD18_REG',
  };
  public static pdexportspiritpd20 = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_EXPORTSPIRIT_PD20_REG',
  };
  public static mixedexportspiritpd20 = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_EXPORTSPIRIT_PD20_REG',
  };
  public static indexportspiritpd20 = {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_EXPORTSPIRIT_PD20_REG',
  };
  public static pdindentrequestomd = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_OMD_INDENT_REQUEST',
  }
  public static pdindentacceptomd = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_OMD_INDENT_ACCEPTANCE',
  }
  public static indindentacceptomd = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_OMD_INDENT_ACCEPTANCE',
  }
  public static mixedindentacceptomd = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_OMD_INDENT_ACCEPTANCE',
  }
  public static pdmolassesdeclaration = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_DECLARE_MOLASSES',
  };

  public static pdallotmentrequest = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_ALLOTMENT_MOLASSES',
  };

  public static pdallotmentforeclosure = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_ALLOTMENT_FORECLOSURE',
  };

  public static pdstockactivity = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };
  public static mixedstockactivity = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_STOCK_ACTIVITY',
  };

  public static pdstockavailability = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };

  public static pdstocklog = {
    registrationType: 'BRSLP_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };

  //mixed
  public static mixedstocklog = {
    registrationType: 'BRSLM_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_STOCK_ACTIVITY',
  };
  //ind
  public static indstocklog = {
    registrationType: 'BRSLI_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_STOCK_ACTIVITY',
  };
  //pd
  public static pdindentrequest = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_INDENT',
  };
  public static pdindentrequestvalidityextension = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_INDENT',
  };
  public static pdindentrequestcancellation = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_INDENT',
  };
  public static mixedindentrequest = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_INDENT',
  };
  public static mixedindentrequestvalidityextension = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_INDENT',
  };
  public static mixedindentrequestcancellation = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_INDENT',
  };
  public static pdindentforclosure = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_INDENT_FORECLOSURE',
  };
  public static pdindentforeclosureomd = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: indParfix,
    workflowSubModule: 'PD_OMD_INDENT_FORE_CLOSURE',
  };

  public static pdmf4received = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_INDENT_FORECLOSURE',
  };

  public static pdmf4acknowledgement = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_INDENT_FORECLOSURE',
  };

  public static pdmf6partone = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_MF6_PART1',
  };


  public static pdmf6parttwo = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_MF6_PART2',
  };

  public static pdpd5 = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_MF6_PART2',
  };

  public static pdclb15 = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: 'CLB15',
  };


  public static pdclb16 = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: 'CLB16',
  };

  public static pdbottlinglinedismantle = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_BOTLINE_DISMANTLE',
  };

  public static pdbottlinglineconversion = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_BOTLINE_CONV',
  };

  public static pdbottlinglineoverview = {  //  not completed 
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_BOTLINE_MAINTAIN',
  };

  public static pdbottlinglinerepair = {  //  not completed 
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_BOTLINE_REPAIR',
  };
  public static pdnewlineregistration = {  //  not completed 
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_BOTLINE_REG',
  };


  ///ind
  public static indmolassesdeclaration = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_DECLARE_MOLASSES',
  };
  public static indallotmentrequest = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_ALLOTMENT_MOLASSES',
  };
  public static indallotmentforeclosure = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_ALLOTMENT_FORECLOSURE',
  };
  public static indstockactivity = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_STOCK_ACTIVITY',
  };
  public static indstockavailability = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_STOCK_ACTIVITY',
  };
  public static indindentrequest = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_INDENT',
  };
  public static indindentrequestvalidityextension = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_INDENT',
  };
  public static indindentrequestcancellation = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_INDENT',
  };
  public static indindentrequestomd = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: 'INDIST_OMD_INDENT_REQUEST',
  }
  public static indindentforeclosureomd = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: 'ID_OMD_INDENT_FORE_CLOSURE',
  };
  public static indindentforclosure = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_INDENT_FORECLOSURE',
  };

  public static indmf4received = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_INDENT_FORECLOSURE',
  };

  public static indmf4acknowledgement = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_INDENT_FORECLOSURE',
  };

  public static indmf6partone = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_MF6_PART1',
  };
  public static indmf6parttwo = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_MF6_PART2',
  };

  public static indpd5 = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_MF6_PART2',
  };

  public static indclb15 = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: 'CLB15',
  };

  public static indclb16 = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: 'CLB16',
  };
  public static indbottlinglinedismantle = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_BOTLINE_DISMANTLE',
  };
  public static indbottlinglineconversion = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_BOTLINE_MAINTAIN',
  };
  public static indbottlinglineoverview = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_BOTLINE_MAINTAIN',
  };

  public static indbottlinglinerepair = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_BOTLINE_REPAIR',
  };

  public static indnewlineregistration = {
    registrationType: 'BR_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_BOTLINE_REG',
  };

  // mixed
  public static mixedmolassesdeclaration = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_DECLARE_MOLASSES',
  };

  public static mixedallotmentrequest = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_ALLOTMENT_MOLASSES',
  };
  public static mixedallotmentforeclosure = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_ALLOTMENT_FORECLOSURE',
  };

  public static mixedtockactivity = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_STOCK_ACTIVITY',
  };
  public static mixedstockavailability = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_STOCK_ACTIVITY',
  };
  public static mixedindentforclosure = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_INDENT_FORECLOSURE',
  };
  public static mixedindentforeclosureomd = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: indParfix,
    workflowSubModule: 'MIXED_OMD_INDENT_FORE_CLOSURE',
  };
  public static mixedmf4received = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_INDENT_FORECLOSURE',
  };
  public static mixedmf4acknowledgement = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_INDENT_FORECLOSURE',
  };
  public static mixedindentrequestomd = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: 'MIXEDIST_OMD_INDENT_REQUEST',
  }
  public static mixedmf6partone = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_MF6_PART1',
  };
  public static mixedmf6parttwo = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_MF6_PART2',
  };
  public static mixedpd5 = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_MF6_PART2',
  };
  public static mixedclb15 = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: 'CLB15',
  };

  public static mixedclb16 = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: 'CLB16',
  };
  public static mixedbottlinglinedismantle = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_BOTLINE_DISMANTLE',
  };
  public static mixedbottlinglineconversion = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_BOTLINE_CONV',
  };
  public static mixedbottlinglineoverview = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_BOTLINE_MAINTAIN',
  };

  public static mixedbottlinglinerepair = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_BOTLINE_REPAIR',
  };
  public static mixednewlineregistration = {
    registrationType: 'BR_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_BOTLINE_REG',
  };

  // starts
  public static "sugarmillindent-metallurgy-inside-up" = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'sugarmill',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };

  public static "sugarmillfoundary-industry-inside-up" = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'sugarmill',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };

  public static "sugarmillyeast-industry-inside-up" = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'sugarmill',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };

  public static "sugarmillindent-request-brick-industry-inside-up" = {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'sugarmill',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };
  // ends

  public static pdenapurchaseorder = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    // workflowModule: pdParfix,
    // workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };
    // pd
  public static pdpd18 = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_ENA_PD18_REG'
  };
    // ind
  public static indpd18 = {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_ENA_PD18_REG'
  };

  //mixed
  public static mixedpd18 = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_ENA_PD18_REG',
  };
  public static pdenapurchaseorderacceptance = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    // workflowModule: pdParfix,
    // workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };

  public static indenapurchaseorder = {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'ID',
    url: 'ind',
    // workflowModule: pdParfix,
    // workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };

  public static pdenamolassesadjustment = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    // workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };

  public static mixedenamolassesadjustment = {
    registrationType: 'PD_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    // workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };
  

  public static indenamolassesadjustment = {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'ID',
    url: 'ind',
    // workflowModule: pdParfix,
    // workflowSubModule: pdParfix + '_STOCK_ACTIVITY',
  };


  // pd
  public static pdaccidentrequest = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_ENA_PD18_REG'
  };
    // ind
  public static indaccidentrequest = {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_ENA_PD18_REG'
  };

  //mixed
  public static mixedaccidentrequest = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_ENA_PD18_REG',
  };

  //vintinery
  public static vintnerynewlineregistration = {
    registrationType: 'BR_',
    entityType: 'VINTNERY',
    moduleName: 'vintnery',
    url: 'vintnery',
    workflowModule: vintineryParfix,
    workflowSubModule: vintineryParfix + '_BOTLINE_REG',
  };

  public static vintnerybottlinglinerepair = {
    registrationType: 'BR_',
    entityType: 'VINTNERY',
    moduleName: 'vintnery',
    url: 'vintnery',
    workflowModule: vintineryParfix,
    workflowSubModule: vintineryParfix + '_BOTLINE_REPAIR',
  };

  public static vintnerybottlinglinedismantle = {
    registrationType: 'BR_',
    entityType: 'VINTNERY',
    moduleName: 'vintnery',
    url: 'vintnery',
    workflowModule: vintineryParfix,
    workflowSubModule: vintineryParfix + '_BOTLINE_DISMANTLE',
  };
//pd18 and pd26
  public static vintnerypd18 = {
    registrationType: 'VI_PD18',
    entityType: 'VINTNERY',
    moduleName: 'vintnery',
    url: 'vintnery',
    workflowModule: vintineryParfix,
    workflowSubModule: vintineryParfix + '_PD18_VINTNERY',
  };
  public static vintnerypd26 = {
    registrationType: 'VI_PD26',
    entityType: 'VINTNERY',
    moduleName: 'vintnery',
    url: 'vintnery',
    workflowModule: vintineryParfix,
    workflowSubModule: vintineryParfix + '_PD26_VINTNERY',
  };

  // Dispatch non reg user pd25
  public static pdnonregpd25= {
    registrationType: 'BR_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_DISPATCH_OF_NON_REGISTER',
  };
  public static indnonregpd25 = {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_DISPATCH_OF_NON_REGISTER'
  };

  //mixed
  public static mixednonregpd25 = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_DISPATCH_OF_NON_REGISTER',
  };

}
