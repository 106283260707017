export class ShopConfig {
  
    public static columns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'shopId',
        displayName: 'Shop ID',
        type: 'string'
      },
      {
        name: 'nameOfShop',
        displayName: 'Shop Name',
        type: 'string'
      },
      {
        name: 'stateDesc',
        displayName: 'State',
        type: 'string'
      },
      {
        name: 'districtDesc',
        displayName: 'District',
        type: 'string'
      },
      {
        name: 'tehsilDesc',
        displayName: 'Tehsil',
        type: 'string',
      },
      // {
      //   name: 'station',
      //   displayName: 'Station',
      //   type: 'string',
      // },
      // {
      //   name: 'circle',
      //   displayName: 'Circle',
      //   type: 'string',
      // },
      {
        name: 'selectorDesc',
        displayName: 'Sector',
        type: 'string',
      },
      {
        name: 'areaDesc',
        displayName: 'Area',
        type: 'string',
      }
    ];

    public static registerRetailShopListColumns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'shopId',
        displayName: 'Shop ID',
        type: 'string'
      },
      {
        name: 'shopName',
        displayName: 'Shop Name',
        type: 'string'
      },
      {
        name: 'stateDesc',
        displayName: 'State',
        type: 'string'
      },
      {
        name: 'districtDesc',
        displayName: 'District',
        type: 'string'
      },
      {
        name: 'tehsilDesc',
        displayName: 'Tehsil',
        type: 'string',
      },
      // {
      //   name: 'station',
      //   displayName: 'Station',
      //   type: 'string',
      // },
      // {
      //   name: 'circle',
      //   displayName: 'Circle',
      //   type: 'string',
      // },
      {
        name: 'selectorDesc',
        displayName: 'Sector',
        type: 'string',
      },
      {
        name: 'areaDesc',
        displayName: 'Area',
        type: 'string',
      }
    ];

    public static appliedColumns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'shopId',
        displayName: 'Shop ID',
        type: 'string'
      },
      {
        name: 'shopName',
        displayName: 'Shop Name',
        type: 'string'
      },
      {
        name: 'stateDesc',
        displayName: 'State',
        type: 'string'
      },
      {
        name: 'districtDesc',
        displayName: 'District',
        type: 'string'
      },
      {
        name: 'tehsilDesc',
        displayName: 'Tehsil',
        type: 'string',
      },
      // {
      //   name: 'station',
      //   displayName: 'Station',
      //   type: 'string',
      // },
      // {
      //   name: 'circle',
      //   displayName: 'Circle',
      //   type: 'string',
      // },
      {
        name: 'selectorDesc',
        displayName: 'Sector',
        type: 'string',
      },
      {
        name: 'areaDesc',
        displayName: 'Area',
        type: 'string',
      },
      {
        name: 'applicationStatusDesc',
        displayName: 'Application Status',
        type: 'string',
      }
    ];

    public static schedulerColumns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'schedulerNumber',
        displayName: 'Scheduler Number',
        type: 'string'
      },
      {
        name: 'financialYearDesc',
        displayName: 'Financial Year',
        type: 'string'
      },
      {
        name: 'zoneDesc',
        displayName: 'Zone',
        type: 'string'
      },
      {
        name: 'totalDistricts',
        displayName: 'Total District',
        type: 'string'
      },
      {
        name: 'licenseTypeDesc',
        displayName: 'Total License Type',
        type: 'string'
      },
      {
        name: 'totalShops',
        displayName: 'Total Shops',
        type: 'string'
      },
      {
        name: 'date',
        displayName: 'Date',
        type: 'string'
      },
      {
        name: 'time',
        displayName: 'Time',
        type: 'string'
      }
    ] 
  
  }
  