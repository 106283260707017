import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class SchedulingInspectionService {
  baseURL = environment.parentUnit;
  masterURL = environment.devisionUrl;
  retailURL = environment.retailURL
  payandreconBaseUrl = environment.payandreconBaseUrl;
  licencemanagementURL = environment.licencemanagementURL;
  licenseURL = environment.licneseBaseUrl;
  baseURLUser = environment.baseURLUser;
  domainUrl = environment.domainUrl;
  paymentURL = environment.paymentDomain;

  constructor(private http: HttpClient) { }

  // search1(payload: any): Observable<any> {
  //   console.log(payload);
  //   const url = this.baseURL + 'customdata/getdata';
  //   return this.http.post(url, payload);
  // }
  //scheduled inspection list

  getlicensetype(payload) {
    const url = this.retailURL + 'userallocatedunit/getapplicationnumbers';
    return this.http.post(url, payload);
  }

  getlicensebasedonentity(value): Observable<any> {

    const url = this.licencemanagementURL + 'license/category/' + value;
    return this.http.get(url);
  }

  //  getlicensetype1(): Observable<any>
  // { 
  //   const url =  'https://testapigateway.upexciseonline.co/licensemanagement/v1.0.0/license/category/BREWERY%2CBOTTLING';
  //   return this.http.get(url);
  //  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
  lazysearch(payload) {
     const url = this.baseURL + 'vigilanceInspection/lazySearch';
    // const url = 'http://localhost:3501/scmProduction/vigilanceInspection/lazySearch'
    return this.http.post(url, payload);
  }
 

  getdistrict(): Observable<any> {
    const url = this.masterURL + 'location/findDistrictByStateCode?stateCode=9';
    //const url = 'https://testapigateway.upexciseonline.co/master/v1.0.0/location/findDistrictByStateCode?stateCode=9';
    return this.http.get(url);
  }
  getComplaintNo(payload): Observable<any> {
    const url = this.baseURL + 'VigilanceAssignComplaint/dropDown';
    return this.http.post(url, payload);
  }


  submitreturnfile(payload) {
    const url = this.baseURL + 'vigilanceInspection';
    //const url = 'http://65.0.150.210:3501/scmProduction/vigilanceInspection';
    return this.http.post(url, payload);
  }



  getUnschInsById(id): Observable<any> {
    const url = this.baseURL + apiUrls.schedulereportIns + '/' + id;
    return this.http.get(url);
  }
 
  addUnschedulInsp(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.schedulereportIns;
    return this.http.post(url, postdata);
  }
  searchUnschedulInsp(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.schedulereportSearch;
    return this.http.post(url, postdata);
  }
  getschInsById(id): Observable<any> {
    const url = this.baseURL + apiUrls.addUnschedIns + '/' + id;
    return this.http.get(url);
  }
  addschedulInsp(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addUnschedIns;
    return this.http.post(url, postdata);
  }
  searchschedulInsp(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.unschedinsSearch;
    return this.http.post(url, postdata);
  }

  getapplicationnumbers(data): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
    return this.http.post(url, {data},);
  }
  getInspectionTypeMaster() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=VIG_INSPEC_TYPE";
    return this.http.get(url)
  }
  // getEntityTypeMaster() {
  //     const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=ENTITY_TYPE";
  //     return this.http.get(url)
  // }
  getEntityTypeMaster(type: String): Observable<any> {
    const url = this.payandreconBaseUrl + 'payandrecon/1.0/EntityTypeMaster/getEntityByModuleCode?moduleCode=' + type;
    return this.http.get(url);
  }

  getdetails(): Observable<any> {
    const url = this.licenseURL + 'license/category/BREWERY'; //DISTILLERY';
    return this.http.get(url);
  }

  getdiscrepancyTypeMaster() {
    const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=DISCREPANCY_TYPE";
    return this.http.get(url)
  }

  getActiveEntityType(postParam): Observable<any> {
    const url = this.baseURLUser + apiUrls.getActiveEntityType;
    return this.http.post(url, postParam);
  }

  getLicenseType1(obj): Observable<any> {
    const url = this.baseURLUser + apiUrls.getLicenseType + obj;
    //const url = this.licenseURL + 'license/category/' + obj;
    return this.http.get(url);
  }


  
  saveupdatepaymentdetails(payload): Observable<any> {
    const url = this.baseURL + 'vigilanceInspectionReport/saveUpdatePaymentDetails';
    return this.http.post(url, payload);
  }
  findpaymentdetails(applicationno, feetype): Observable<any> {
    // let url = this.baseURL + 'sampleRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    let url = this.baseURL + 'vigilanceInspectionReport/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  findAllPaymentDetails(applicationno, feetype): Observable<any> {
    // let url = this.baseURL + 'sampleRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    let url = this.baseURL + 'sampleRequestDescription/findAllPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
    return this.http.get(url);
  }
  licenceAdd(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
    return this.http.post(url, payload);
  }
  downloadDocument(appnumber, liceanseNumber, feeType): any {
    const url = this.baseURL + 'reportReceipt/downloadSampleFeeReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPaymentDetail(payload): Observable<any> {
    const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
    return this.http.post(url, payload);
  }

  getLicenseType(payload): Observable<any>{
    let url = this.baseURL + 'vigilanceInspection/getVigilanceInspectionDropDown?type=' 
    + payload.type + '&dropDownValue=' + payload.dropDownValue 
    + '&entityType='+payload.entityType;
    return this.http.get(url);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
// direct payment call 
  rajkoshPayment(payload): Observable<any> {
    const url = this.paymentURL + '/rajkosh/directrajkoshcall';
    return this.http.post(url, payload);
  }
  getRajkoshStatus(code, payload, money) {
    const url = this.paymentURL + '/rajkosh/getRajkoshChallanStatus?challanNo='+ code + payload +'&amount='+ money;
    return this.http.get(url);
  }
}
