import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {MasterapiService,DistileryService,WorkflowcofigService} from '@app/services';
import { IndentRequestForOmdService } from '@app/services/pd';
import { LocalUrl } from '@app/_constant/stock-local-url';
import * as moment from 'moment';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import { DataService } from '@app/services/payment-and-reconciliation/data.service';
import { NonRegService } from '@app/services/distillery/nonregpd25.service';
import { filter } from 'rxjs-compat/operator/filter';
@Component({
  selector: 'app-list-pd25-form',
  templateUrl: './list-pd25-form.component.html',
  styleUrls: ['./list-pd25-form.component.scss']
})
export class ListPd25FormComponent implements OnInit {

  public actionBtnBasedOnCond = {
    edit: {
      column: 'isEditable',
      condition: true,
    },
  };
  public actionKeys = ['edit', 'view'];
  public pageSize = 10;
  public pageNo = 0;
  public totalCount: number;
  public dataSource = new MatTableDataSource();
  isSearchLoading = false;
  isClearLoading = false;
  CurrectUser=true;
  officerUser=false;
  normalStatus=false;

  public columns = [
    {
      name: 'id',
      displayName: 'parentunit.common.sNo',
      type: 'number'
    },
    {
      name: 'createdDate',
      displayName: 'master.common.date',
      type: 'date',
      dateFormat: "dd/MM/yyyy"
    },
    {
      name: 'applicationNumber',
      displayName: 'Application Number',
      type: 'string'
    },
    // {
    //   name: 'Entity Name',
    //   displayName: 'Generating for',
    //   type: 'string'
    // },
    {
      name: 'consignorName',
      displayName: 'Distillery Name',
      type: 'string'
    },
    {
      name: 'consignorAddress',
      displayName: 'Distillery Address',
      type: 'string'
    },
    {
      name: 'consigneeEntityType',
      displayName: 'Entity Type',
      // displayFcn: (item: any) => item.pd18 != null ? item.requestedQuantityBl : '',
      type: 'string'
    },
    {
      name: 'consigneeEntityName',
      displayName: 'Entity Name',
      // displayFcn: (item: any) => item.pd18 != null ? item.requestedQuantityBl : '',
      type: 'string'
    },
    {
      name: 'spiritType',
      displayName: 'Spirit Type',
      // displayFcn: (item: any) => item.pd18 != null ? item.requestedQuantityBl : '',
      type: 'string'
    },
    {
      name: 'issueQty',
      displayName: 'Issued Qty',
      // displayFcn: (item: any) => item.pd18 != null ? item.requestedQuantityBl : '',
      type: 'string'
    },
    {
      name: "stageDesc",
      displayName: 'Currently With',
      displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT'
        || item.status == 'SUBMITTED' || item.stageDesc == 'Completed') ? '-' : item.stageDesc,
      type: 'objstring'
    }, {
      name: "statusDesc",
      displayName: "master.common.status",
      type: 'options',
      badgeColors: {
        'In Progress': 'badge-secondary',
        'Approved': 'badge-success',
        'COMPLETED': 'badge-info',
        'Submitted': 'badge-info',
        'Clarification': 'badge-warning',
        'DRAFT': 'badge-primary',
        'Draft': 'badge-primary',
        'Rejected': 'badge-danger',
        'Validity Expired':'badge-danger'
      },

    }
  ];
  public statuslist = [{ name: "Submitted", value: "Submitted" }, { name: "Draft", value: "Draft" },
    { name: "In Progress", value: "In Progress" }, { name: "Approved", value: "Approved" }, { name: "Rejected", value: "Rejected" },
    { name: "Clarification", value: "Clarification" }];
  isCustomer: boolean = false;
  status: any;

  Obj: any = {
    application: '',
    date: '',
    status: '',
    consigneeName: '',
    licenseType: '',
    licenseNumber: '',
    entityType: ''
  };

    // common setting 
    url: any;
    registrationType: any;
    moduleName: any;
    workflowModule: any;
    workflowSubModule: any;
    entityType: any
  data: unknown[];
  constructor(
     private router: Router,
        private distileryService: IndentRequestForOmdService,
        private masterapiService: MasterapiService,
        private workconfig: WorkflowcofigService,
        private NonRegService: NonRegService,
        private datepipe: DatePipe,
        private dataService: DataService,
  ) { }

  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    let subUrlManage = currentUrlArray[1] + currentUrlArray[2];     
    subUrlManage = subUrlManage.replace(/-/g, ""); 
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowModule = LocalUrl[subUrlManage].workflowModule;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;

    this.isCustomer = (localStorage.getItem('IS_CUSTOMER') === 'true');
    // this.isCustomer = (localStorage.getItem('SCM_SUBUSER_ADD_ACCESS') === 'true');

   this.getList();
    // this.getstatuscount();
  }
  isLoadingResults: boolean = true;
   getList(status?: any) {
      this.status = status ? status : this.Obj.status.trim();
      sessionStorage.setItem('cardstatus', this.status)
      // const createdDate = this.Obj.date ? (this.Obj.date.startDate != null) ? moment(this.Obj.date.startDate).format("DD-MM-YYYY") : '' : '';
      let payload : any = {
        filters: {
          createdDate: this.Obj.date ? moment(this.Obj.date).format("DD-MM-YYYY") : '',
          applicationNumber: this.Obj.application.trim(),
          status: this.status,
          consigneeName: this.Obj.consigneeName.trim(),
          licenseType: this.Obj.licenseType,
          licenseNumber: this.Obj.licenseNumber,
          entityType: this.entityType,
  
        },
        pageNo: this.pageNo,
        paginationSize: this.pageSize
      }
      if(this.CurrectUser  == true){
        payload.filters['isCurrentOfficerAllRecords']=true;
        payload.filters['isAllOfficerRecords']=false;
        payload.filters['isOtherOfficerAllRecords']=false;
      }else if(this.officerUser == true){
        payload.filters['isCurrentOfficerAllRecords']=false;
        payload.filters['isAllOfficerRecords']=false;
        payload.filters['isOtherOfficerAllRecords']=true;
      }else if(this.normalStatus == true){
        payload.filters['isCurrentOfficerAllRecords']=false;
        payload.filters['isAllOfficerRecords']=true;
        payload.filters['isOtherOfficerAllRecords']=false;
      }
    //   let allocatedlicensenumber=localStorage.getItem('SCM_GETALLOCATED_LICENSENUMBER')
    //   if(allocatedlicensenumber){
    //   let officerallocatedlicensedetails =JSON.parse(allocatedlicensenumber).map(obj => obj.licenseNumber);
    //   payload.searchInput.licenseNumber=officerallocatedlicensedetails; 
    // } 
    // if(localStorage.getItem('SCM_SETENTITYTYPEFOROFFICER')){     
    //   let officerallocatedentitylist = localStorage.getItem('entityList').split(',');  
    //    payload.searchInput.entityList=officerallocatedentitylist
    //    payload.searchInput.entityType=officerallocatedentitylist 
    //   }
      this.isLoadingResults = true;
      this.NonRegService.getList(payload).subscribe((responceData: any) => {
        this.totalCount = responceData.data.totalElements;
        this.dataSource.data = responceData.data.contents;
        // this.isCustomer = responceData.data.isCustomer ? responceData.data.isCustomer : false;
        this.data = this.dataSource.data;
        this.isLoadingResults = false;
      });
  
    }
    changeStatus(status){
      if(status == 'current'){
        this.CurrectUser = true;
        this.officerUser = false;
        this.normalStatus = false;
      }else if(status=='Officer'){
        this.officerUser = true;
        this.CurrectUser = false;
        this.normalStatus = false;
      }else if(status=='normal'){
        this.normalStatus = true;
        this.CurrectUser = false;
        this.officerUser = false;
      }
    }

    handlePagination(event) {
      this.pageNo = event.currentPage;
      this.pageSize = event.pageSize;
      this.getList(sessionStorage.getItem('cardstatus'));
    }
    // getstatuscount() {
    //   let payload :any = {
    //     entityType: this.entityType,
    //     screenName: "SPIRIT_EXPORT_PD18"
    //   }
    //   if(this.iscustomer){
    //     let parentUserId = JSON.parse(localStorage.getItem('USER_DET')).parentUserId;
    //     if (null != parentUserId && undefined != null) {
    //       payload.parentUserId = parentUserId;
    //     }
    //   }
    //     let allocatedlicensenumber = localStorage.getItem('SCM_GETALLOCATED_LICENSENUMBER')
    //     if (allocatedlicensenumber) {
    //       let officerallocatedlicensedetails = JSON.parse(allocatedlicensenumber).map(obj => obj.licenseNumber);
    //       payload.licenseNumberlist = officerallocatedlicensedetails;
    //     }
    //   if (this.entityType == "PD") {
    //     this.pd18idService.getPdCardCount(payload)
    //       .subscribe((res: any) => {
    //         this.dataCount = res.data;
    //       });
    //   } else if (this.entityType == "ID") {
    //     this.pd18idService.getPdCardCount(payload)
    //       .subscribe((res: any) => {
    //         this.dataCount = res.data;
    //       });
    //   } else if (this.entityType == "MIXED") {
    //     this.pd18idService.getPdCardCount(payload)
    //       .subscribe((res: any) => {
    //         this.dataCount = res.data;
    //       });
    //   }
  
    // }
    onView(event: any) {
      this.router.navigate(['/' + this.url+ '/nonregpd25/view/' + event.id]);
    }
  
    onEdit(event) {
        this.router.navigate(['/' + this.url + '/nonregpd25/edit/', event.id]);
      
    }
    clearData() {
      this.Obj.date = '';
      this.Obj.application = '';
      this.status = '';
      this.Obj.consigneeName = '';
      this.Obj.licenseType = '';
      this.Obj.licenseNumber = '';
      this.Obj.status = '';
      this.getList()
    }

     addressproofListHeader = [
        'Date Of Application',
        'Application Number',
        // "Generating for",
        'Distillery Name',
        'Distillery Address',
        'Entity Type',
        'Entity Name',
        'Spirit Type',
        'Issued Qty',
        'Currently With',
        'Status'
      ];
    
      addressproofListTableKeys = [
        {
          data: 'createdDate',
          render: function (data, type, row) {
            return (data ? this.datepipe.transform(data, 'yyyy-MM-dd') : '');
          }
        },
        {
          data: 'applicationNumber',
          "className": "text-capitalize",
          render: function (data, type, row) {
            return (data ? data : '');
          }
        },
        // {
        //     data: 'consigneeEntityName',
        //     "className": "text-capitalize",
        //     render: function (data, type, row) {
        //       return (data ? data : '');
        //     }
        // },
        {
          data: 'consignorName',
          "className": "text-capitalize",
          render: function (data, type, row) {
            return (data ? data : '');
          }
        },
        {
          data: 'consignorAddress',
          "className": "text-capitalize",
          render: function (data, type, row) {
            return (data ? data : '');
          }
        },
        {
          data: 'consigneeEntityType',
          "className": "text-capitalize",
          render: function (data, type, row) {
            return (data ? data : '');
          }
        },
        {
          data: 'consigneeEntityName',
          "className": "text-capitalize",
          render: function (data, type, row) {
            return (data ? data : '');
          }
        },
        {
          data: 'spiritType',
          "className": "text-capitalize",
          render: function (data, type, row) {
            return (data ? data : '');
          }
        },
        {
          data: 'issueQty',
          "className": "text-capitalize",
          render: function (data, type, row) {
            return (data ? data : '');
          }
        },
        {
          data: 'stageDesc',
          "className": "text-center",
          render: function (data, type, row) {
            return (data ? data : '');
          }
        },
        {
          data: 'statusDesc',
          "className": "text-center",
          render: function (data, type, row) {
            return (data ? data : '');
          }
        }
      ]
    
      ExportTOExcel() {
        const data = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
        this.dataService.downloadExcel(data, 'Dispatch Non Register User PD 25', [this.addressproofListHeader, this.addressproofListTableKeys]);
      }
    
      generatePdf() {
        this.addressproofListHeader.splice(0, 0, "Sr.No");
        let head = [this.addressproofListHeader]
        let data = [];
        this.dataSource.data.forEach((element: any, i: number) => {
          data[i] = [];
          data[i].push(element ? i + 1 : '')
          data[i].push(element ? this.datepipe.transform(element.createdDate, 'dd/MM/yyyy') : '');
          data[i].push(element ? element.applicationNumber : '');
          // data[i].push(element ? element.consigneeEntityType : '');
          data[i].push(element ? element.consignorName : '');
          data[i].push(element ? element.consignorAddress : '');
          data[i].push(element ? element.consigneeEntityType : '');
          data[i].push(element ? element.consigneeEntityName : '');
          data[i].push(element ? element.spiritType : '');
          data[i].push(element ? element.issueQty : '');
          data[i].push((element.status == 'APPROVED' || element.status == 'DRAFT' || element.status == 'SUBMITTED' || element.stageDesc == 'Completed') ? '-' : element.stageDesc);
          data[i].push(element ? element.statusDesc : '');
        });
        var doc = new jsPDF({
          unit: 'pt',
          format: 'a4',
          orientation: 'landscape' // or 'landscape'
        });
    
        doc.setFontSize(18);
        doc.text('Dispatch Non Register User PD 25', 38, 30);
        doc.setFontSize(11);
        doc.setTextColor(100);
    
        (doc as any).autoTable({
          head: head,
          body: data,
          theme: 'plain',
          margin: { top: 50, bottom: 20 },
          bodyStyles: {
            lineWidth: 0.2,
            lineColor: [0, 0, 0]
          },
    
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.5,
          headerStyles: {
            lineWidth: 0.5,
            lineColor: [0, 0, 0]
          },
          didDrawCell: data => {
          }
        })
        this.addressproofListHeader.splice(0, 1);
        // below line for Download PDF document  
        doc.save('Dispatch Non Register User PD 25.pdf');
      }
}
