import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalUrl } from '@app/app-local-url';
import { AlertService, DistileryService, WorkflowcofigService } from '@app/services';
import { TransportPassService } from '@app/services/brewery/transportpass.service';

@Component({
  selector: 'app-addaccidentalomd',
  templateUrl: './addaccidentalomd.component.html',
  styleUrls: ['./addaccidentalomd.component.scss']
})
export class AddaccidentalomdComponent implements OnInit {

  url: any;
  registrationType: any;
  moduleName: any;
  workflowModule: any;
  workflowSubModule: any;
  entityType: any;
  isCustomer: boolean;
  designation: string;
  
  accidentalRequestOmd: FormGroup;
  addedit: string;
  omdPd25Details: any;
  addressDetails: any;

  districtData: any = [];
  formSubmitted = false;
  applicationNumber: any;
  workflowdata: any;
  editId: any;
  editData: any;
  isSendback: boolean;
  isDrafthide: any = true;
  isQrCodeDisabled:boolean=false;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private distilleryservice: DistileryService,
    private datePipe: DatePipe,
    public transportpassservice: TransportPassService,
    private alert: AlertService,
    private workflowservice: WorkflowcofigService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    this.isCustomer = (localStorage.getItem('IS_CUSTOMER') === 'true');
    this.designation = (localStorage.getItem('DESIGNATION'));
    const subUrlManage = currentUrlArray[1] + currentUrlArray[3];
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;

    this.addedit = this.router.url.includes('add') == true ? 'Add' : 'Edit';
    this.accidentalRequestOmd = this.formBuilder.group({
      requestDate: ['', Validators.required],
      omdPassNo: ['', Validators.required],
      tpIssueDate: [''],
      validUpTo: [''],
      accidentalDate: ['', Validators.required],
      // accidentalDomainValue: ['',Validators.required],
      accidentalDomainCode: ['', Validators.required],
      // accidentalDistrictValue: ['',Validators.required],
      accidentalDistrictCode: ['', Validators.required],
      accidentalAddress: ['', Validators.required],
      userRemarks: ['', Validators.required],
      comments: ['']
    })

    this.route.params.subscribe(params => {
      if (params.id) {
        this.editId = params.id;
        this.setData(this.editId);
      }
    });
  }
  getForm() { return this.accidentalRequestOmd; }
  getFormCntr(element) {
    return this.getForm().get(element);
  }
  setData(id){
    setTimeout(() => {
      this.distilleryservice.getAccidentalOmd(id).subscribe((responceData: any) => {
        if (responceData.status === 's') {
         this.editData = responceData.data;
         if (this.editData.status == 'SENDBACK') {
          this.accidentalRequestOmd.controls['comments'].setValidators(Validators.required);
          this.isSendback = true;
          this.isDrafthide = false;
        }
          this.accidentalRequestOmd.patchValue({ 
            requestDate: this.datePipe.transform(this.editData.requestDate, 'yyyy-MM-dd'),
            omdPassNo: this.editData.omdPassNo,
            tpIssueDate: this.datePipe.transform(this.editData.tpIssueDate, 'dd/MM/yyyy'),
            validUpTo: this.datePipe.transform(this.editData.validUpTo, 'dd/MM/yyyy'), 
            accidentalDate: this.datePipe.transform(this.editData.accidentalDate,'yyyy-MM-dd'),
            accidentalDomainCode: this.editData.accidentalDomainCode,
            accidentalDistrictCode: this.editData.accidentalDistrictCode,
            accidentalAddress: this.editData.accidentalAddress,
            userRemarks: this.editData.userRemarks
          });
          this.scanNumber();
        }
     });
    },400);
  }
  scanNumber() {
    const payload: any = {
      searchInput: {
        status: 'APPROVED',
        applicationNumber: this.accidentalRequestOmd.value.omdPassNo,
        profileType: "OMD",
        entityType: this.entityType,
        isAccidentalRequestScan : true
      },
      "page": 0,
      "pageSize": 0,
    };

    this.distilleryservice.getPD25(payload).subscribe((responceData: any) => {
      if(responceData.errorCode == 200){
        this.isQrCodeDisabled=true;
        this.omdPd25Details = responceData.data.contents[0];
        this.addressDetails = this.omdPd25Details.pd26.omdIndentRequest;
        if (this.omdPd25Details.length != 0) {
          this.accidentalRequestOmd.patchValue({
            tpIssueDate: this.datePipe.transform(this.omdPd25Details.createdDate, 'yyyy-MM-dd'),
            validUpTo: this.datePipe.transform(this.omdPd25Details.pd26.validUpto, 'yyyy-MM-dd hh:mm a'),
          });
          const requestParam = { "dataCode": "TRANSPORT_PASS_ROUTE_DETAILS", "placeholderKeyValueMap": {} }
          this.transportpassservice.scanIndentNumber(requestParam)
            .subscribe((resp: any) => {
              const { statusCode, responseMessage, responseContent } = resp;
              if (statusCode === 0) {
                const { responseContent: dataList, totalElements, numberOfElements } = responseContent;
                this.districtData = responseContent
                console.log(this.districtData)
              }
            });
        } 
        this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
      }else {
        this.alert.showError(responceData.userDisplayMesg, 'error');
      }
    });
  }
  getapplicationnumber() {
    let model = {
      licenseNumber: this.accidentalRequestOmd.value.licenseno,
      registrationType: this.entityType + '_'
    }
    this.distilleryservice.getapplicationnumbers(model).subscribe((data: any) => {
      if (data.content) {
        this.applicationNumber = data.content.ApplicationNumber;
      }
    })
  }
  getWorkflowData(value) {  
    if (value == 'WITHIN_UP') {
      this.workflowservice.getworkflowbydesignation(this.entityType, this.workflowSubModule).subscribe((resp: any) => {
        this.workflowdata = resp.content;
        this.getWorkflowData1(value);
      })
    } else {
      this.workflowservice.getworkflowbydesignation(this.entityType, this.entityType + '_ACCIDENTAL_REQUEST_OMD_OUTSIDE_UP').subscribe((resp: any) => {
        this.workflowdata = resp.content;
        this.getWorkflowData1(value);
      })
    }
  }
  
  getWorkflowData1(value) {
    // if (value == 'WITHIN_UP') {     
    //   this.districtData = [];
    //   this.accidentalRequestOmd.patchValue({ 
    //     accidentalDistrictCode :""
    //   })

    //     this.districtData.push({ 'name': this.omdPd25Details.districtDesc, 'code': this.omdPd25Details.districtCode });   
    // } else {      
      this.districtData=[];
      this.accidentalRequestOmd.patchValue({
        accidentalDistrictCode :""
      })
      const requestParam = { "dataCode": "TRANSPORT_PASS_ROUTE_DETAILS", "placeholderKeyValueMap": {} }
          this.transportpassservice.scanIndentNumber(requestParam)
            .subscribe((resp: any) => {
              const { statusCode, responseMessage, responseContent } = resp;
              if (statusCode === 0) {
                const { responseContent: dataList, totalElements, numberOfElements } = responseContent;
                this.districtData = responseContent
              }
            });
    // }
  }
  save(form, status) {
    console.log(form)
    if (status === 'SUBMITTED' && this.accidentalRequestOmd.valid === false) {
      this.formSubmitted = true;
      return;
    } else {
      let payload = {
        // "transferType": "TANKER",
        "applicationNumber": this.applicationNumber,
        "entityType": this.entityType,
        "event": "INITIATE",
        "isDigitalSignature": this.workflowdata ? this.workflowdata.isDigitalSignature : false,
        "isEditable": true,
        "isShowButtons": true,
        "stage": 'Level 1',
        "stageDesc": 'Level 1',
        "level": this.workflowdata ? this.workflowdata.level : 'Level 1',
        "requestDate": this.accidentalRequestOmd.value.requestDate ? new Date(this.accidentalRequestOmd.value.requestDate).toISOString(): '',
        "omdPassNo": this.accidentalRequestOmd.value.omdPassNo ? this.accidentalRequestOmd.value.omdPassNo : '',
        "tpIssueDate": this.accidentalRequestOmd.value.tpIssueDate ? new Date(this.accidentalRequestOmd.value.tpIssueDate).toISOString() : '',
        "validUpTo": this.accidentalRequestOmd.value.validUpTo ? new Date(this.accidentalRequestOmd.value.validUpTo).toISOString() : '',
        "consignorLicenseType": this.omdPd25Details ? this.omdPd25Details.licenseType : '',
        "consignorLicenseNo": this.omdPd25Details ? this.omdPd25Details.licenseNumber : '',
        "consignorUnitName": this.omdPd25Details ? this.omdPd25Details.unitName : '',
        "consignorUnitAddress": this.addressDetails ? this.addressDetails.address : '',
        "consigneeLicenseNumber": this.omdPd25Details ? this.omdPd25Details.pd26.omdLicenseNo : '',
        "consigneeLicenseType": this.omdPd25Details ? this.omdPd25Details.pd26.licenseNumber : '',
        "consigneeUnitName": this.omdPd25Details ? this.omdPd25Details.pd26.omdName : '',
        "consigneeUnitAddress": this.omdPd25Details ? this.omdPd25Details.pd26.omdAddress : '',
        "indentNo": this.omdPd25Details ? this.omdPd25Details.pd26.indentNo : '',
        "indentDate": this.omdPd25Details ? new Date(this.omdPd25Details.pd26.indentDate).toISOString() : '',
        "denaturationFees": this.omdPd25Details ? this.omdPd25Details.denaturedFee : '',
        "dispatchLicenseFees": (this.omdPd25Details && this.omdPd25Details.orderForValue == 'OMD Within Up') ? this.omdPd25Details.totalFee : 0,
        "accidentalDate": this.accidentalRequestOmd.value.accidentalDate ? this.accidentalRequestOmd.value.accidentalDate : '',
        "accidentalDomainCode": this.accidentalRequestOmd.value.accidentalDomainCode ? this.accidentalRequestOmd.value.accidentalDomainCode : '',
        "accidentalDomainValue": this.accidentalRequestOmd.value.accidentalDomainCode == 'WITHIN_UP' ? 'Within Up' : 'Outside Up',
        "accidentalDistrictCode": this.accidentalRequestOmd.value.accidentalDistrictCode ? this.accidentalRequestOmd.value.accidentalDistrictCode : '',
        "accidentalDistrictValue": this.districtData ? this.districtData.find(res => res.code == this.accidentalRequestOmd.value.accidentalDistrictCode).name : '',
        "accidentalAddress": this.accidentalRequestOmd.value.accidentalAddress ? this.accidentalRequestOmd.value.accidentalAddress : '',
        "userRemarks": this.accidentalRequestOmd.value.userRemarks ? this.accidentalRequestOmd.value.userRemarks : '',
        "profileType": "ACCIDENTAL_REQUEST_OMD",
        "accidentalRequestOmdDocumentList": [],
        "pd25TransferDetails": [],
        "previousPd25ChemberDetails": [],
        "previousPd25TransportDetails": null,
        "updatedPd25ChemberDetails": [],
        "updatedPd25TransportDetails": null,
        "status": status,
        "statusDesc": status,
        "licenseNumber": this.omdPd25Details ? this.omdPd25Details.licenseNumber : '',
        "licenseType": this.omdPd25Details ? this.omdPd25Details.licenseType : '',
        "countryCode": this.omdPd25Details ? this.omdPd25Details.countryCode :'',
        "stateCode": this.omdPd25Details ? this.omdPd25Details.stateCode :'',
        "districtCode": this.omdPd25Details ? this.omdPd25Details.districtCode :'',
        "unitCode": this.omdPd25Details ? this.omdPd25Details.unitCode :'',
        "licenseSubCategory": this.omdPd25Details ? this.omdPd25Details.licenseSubCategory :'',
        "districtDesc": this.omdPd25Details ? this.omdPd25Details.districtDesc :'',
        "parentUserId":  JSON.parse(localStorage.getItem('USER_DET')).parentUserId
      }
      if (this.editId) {
        payload['id'] = this.editId;
        payload['createdBy'] = this.editData.createdBy;
        payload['createdDate'] = this.editData.createdDate;
        payload['applicationNumber']= this.editData.applicationNumber;
        if (this.editData.status == 'SENDBACK') {
          payload['comments'] = this.accidentalRequestOmd.value.comments;
        }
      }
      // payload['pd26RoutDetails'] = pd26RoutDetails;
      this.distilleryservice.saveAccidentalOmd(payload).subscribe((responceData: any) => {
        if (responceData['status'] === 's') {
          this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
          this.router.navigate(['/' + this.url + '/omd/accidentalomd/list']);
        } else {
          this.alert.showError(responceData.userDisplayMesg);
        }
      });
    }
  }
}
