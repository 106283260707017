import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NonRegService {
    constructor(private http: HttpClient) { }
    baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    scmOther = environment.scmOtherModules

    addetails(data): Observable<any> {
        const url = this.scmOther + 'dispatchOfSpiritToNonRegister';
        return this.http.post(url, data);
      }
      getList(payload): Observable<any> {
        const url = this.scmOther + 'dispatchOfSpiritToNonRegister/lazySearch';
        return this.http.post(url, payload);
      }
      getById(id): Observable<any> {
        const url = this.scmOther + 'dispatchOfSpiritToNonRegister/' + id;
        return this.http.get(url);
      }
      getapplicationnumbers(data) {
        const url = this.scmOther + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
        return this.http.post(url, {});
      }
      downloadinspection(payload){
        const url = this.scmOther + 'reportReceipt/downloadReceipt'
        //const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
        return this.http.get(url,{ responseType: 'blob', params:payload });
      }
}