import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, DocumentUploadService,DistileryService, PreviewService, StockActivityService,TransportGenService, WorkflowcofigService } from '@app/services';
import * as fileSaver from 'file-saver';
import { LocalUrl } from '@app/_constant/stock-local-url';
import * as moment from 'moment';
import { NonRegService } from '@app/services/distillery/nonregpd25.service';
import { enaPurchaseOrderService } from '@app/services/pd/enapurchaseorder.service';

@Component({
  selector: 'app-view-pd25-form',
  templateUrl: './view-pd25-form.component.html',
  styleUrls: ['./view-pd25-form.component.scss']
})
export class ViewPd25FormComponent implements OnInit {
  @ViewChild('exampleModalCenter', { static: false }) exampleModalCenter;
  isCustomer: boolean;
  logshow: any[] = []
  workflowdata: any;
  isApprovedLoading = false;
  isRejectLoading = false;
  isClarificationLoading = false;
  formSubmitted: boolean;
  pd25Form: FormGroup;
  confirmStatus: string = "";
  licenseDetailResponse: any;
  afterDecimal: number;
  routeRangeList: any;
  validUpToHrs: any;
  editObject: any;
  pd26TransferDetails: any;
  logactive: boolean = false;
  isPreview = false
  viewId: any;
  uploaddata: any;

  constructor( private distilleryService: DistileryService,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private formBuilder: FormBuilder,
    private previewservice: PreviewService, private worfFlowService: WorkflowcofigService,
    private transportGenService:TransportGenService,
    private NonRegService:NonRegService,private documentUploadService: DocumentUploadService,
  private entPurchaseOrderService: enaPurchaseOrderService,) { }
 // common setting 
 url: any;
 registrationType: any;
 moduleName: any;
 workflowModule: any;
 workflowSubModule: any;
 entityType: any
data: unknown[];
  ngOnInit() {
    const currentUrl = this.router.url;
    let currentUrlArray = currentUrl.split('/');
    let subUrlManage = currentUrlArray[1] + currentUrlArray[2];     
    subUrlManage = subUrlManage.replace(/-/g, ""); 
    this.url = LocalUrl[subUrlManage].url;
    this.registrationType = LocalUrl[subUrlManage].registrationType;
    this.entityType = LocalUrl[subUrlManage].entityType;
    this.moduleName = LocalUrl[subUrlManage].moduleName;
    this.workflowModule = LocalUrl[subUrlManage].workflowModule;
    this.workflowSubModule = LocalUrl[subUrlManage].workflowSubModule;

    this.isCustomer = (localStorage.getItem('IS_CUSTOMER') === 'true');
    this.createFormGrp();
    this.isPreview = this.router.url.includes('preview') ? true : false;
    this.route.params.subscribe(params => {
      if (params.Id) {
        this.viewId = params.Id;
        this.getbyiddata(params.Id);
      }
    })
  }

 getbyiddata(id) {
    this.NonRegService.getById(id).subscribe((responceData: any) => {
      if (responceData.status === 's') {
        this.editObject = responceData.data;
        this.licenseDetailResponse = this.editObject.licenseDetailsResponse
        this.uploaddata = responceData.data.nonRegisterPD25DocList[0];
        this.pd25Form.patchValue({
          validUpToHrs:this.editObject.validUpToHrs,
          validUpTo: new Date(this.editObject.validUpTo).toISOString(),
        })
        this.getapprovallogsList();
        this.getworkflow();
      }
    })
  }

  createFormGrp() {
    this.pd25Form = this.formBuilder.group({
      remark: ['', [Validators.required, Validators.maxLength(100)]],
      sendBackLevel: [''],
      validUpToHrs:[''],
      validUpTo:['']

    });
  }
  get brandform() {
    return this.pd25Form.controls;
  }

  getapprovallogsList() {
    this.worfFlowService.getapprovallogs(this.editObject.applicationNumber)
      .subscribe((resp: any) => {
        if (resp) {
          this.logactive = true;
          this.logshow = resp;
        }
      });
  }
  getworkflow() {
    this.worfFlowService.getworkflowbydesignation(this.workflowModule, this.workflowSubModule).subscribe((resp: any) => {
      this.workflowdata = resp.content;
      console.log(this.workflowdata);
      if (this.workflowdata) {
        if (this.workflowdata.isSendBack != undefined && this.workflowdata.isSendBack) {
          this.pd25Form.controls['sendBackLevel'].setValidators(Validators.required);
        } else {
          this.pd25Form.controls['sendBackLevel'].setErrors(null);
        }
      } else {
        this.pd25Form.controls['sendBackLevel'].setErrors(null);
      }
    });
  }

   showConfirmation(status, form) {
    this.confirmStatus = status;
    this.formSubmitted = false;
    // await this.validuptoValidation1();
    // this.validUpToHrs = this.routeRangeList.find((resp: any) => resp.paramKey === this.editObject.distance).additionalValue2
    
    // if(parseFloat(this.pd25Form.get('validUpToHrs').value ) > this.validUpToHrs){
    //   this.alert.showError("Valid Upto (hrs) should not greater than "+this.validUpToHrs +"(hrs)");
    //   return false;
    // }
    // if(this.pd25Form.get('validUpToHrs').value == 0 ||this.pd25Form.get('validUpToHrs').value == 'null'
    // || this.pd25Form.get('validUpToHrs').value == ''){
    //   this.alert.showError("Valid Upto (hrs/hrs.min) field is required");
    //   return false;
    // }
    if (status == 'SENDBACK') {
      this.pd25Form.get('sendBackLevel').setValidators(Validators.required);
      this.pd25Form.controls['sendBackLevel'].updateValueAndValidity();
    }

    else {
      this.pd25Form.patchValue({
        sendBackLevel: ''
      })
      this.pd25Form.get('sendBackLevel').setErrors(null);

    }
    console.log(form);
    if (form.valid === false) {
      this.formSubmitted = true;
    }
    else {
      this.toggleLoading(status)
      this.exampleModalCenter.show()
    }

  }
  close() {
    this.exampleModalCenter.hide()
    this.isApprovedLoading = false
    this.isRejectLoading = false;
    this.isClarificationLoading = false
  }

  toggleLoading(saveType) {
    switch (saveType) {
      case 'APPROVED':
        this.isApprovedLoading = !this.isApprovedLoading;
        break;
      case 'REJECTED':
        console.log(!this.isRejectLoading)
        this.isRejectLoading = !this.isRejectLoading;
        break;
      case 'SENDBACK':
        console.log(!this.isClarificationLoading)
        this.isClarificationLoading = !this.isClarificationLoading;
        break;
    }
  }
  feedback(data) {

    const approveObj = {
      applicationNumber: this.editObject.applicationNumber,
      id: parseInt(this.viewId),
      isDigitalSignature: this.workflowdata ? this.workflowdata.isDigitalSignature : true,
      event: data,
      stage: this.editObject.stage,
      stageDesc: this.editObject.stageDesc,
      comments: this.pd25Form.value.remark,
      status: data,
      isProcessingFeePaid: this.editObject.isProcessingFeePaid,
      sentBackLevels: this.pd25Form.value.sendBackLevel ? this.pd25Form.value.sendBackLevel : '',
      level: this.workflowdata ? this.workflowdata.level : 'Level 2',
      entityType: this.entityType,
      // isEna: false,
      // isOmd: true,
      // profileType: "OMD_PD18",
      // validUpToHrs:this.pd25Form.value.validUpToHrs,
      // validUpto: new Date(this.pd25Form.get('validUpto').value).toISOString(),
    };
    this.NonRegService.addetails(approveObj).subscribe((responceData: any) => {
      if (responceData.errorCode == 201) {
        this.alert.showSuccess(responceData.userDisplayMesg, 'Success');
        this.router.navigate(['/' + this.url + '/nonregpd25/list']);
      } else {
        this.alert.showError(responceData.userDisplayMesg, 'error');
      }
    });
  }


  closes() {
    this.router.navigate(['/' + this.url + '/nonregpd25/edit/' + this.editObject.id])
  }
  async validuptoValidation1(){
    let responceData:any = await this.transportGenService.getAllRouteRange1()
    // .subscribe((responceData: any) => {
      if (responceData.status === "s") {
        // console.log('distance resp', responceData);
        this.routeRangeList = responceData.data.downDropDownMasterValueList.sort(function (a, b) { return a.id - b.id });
      }
    // });
  }
  validuptoValidation(){
    var hours = Math.floor(this.pd25Form.get('validUpToHrs').value); // Extract the whole number part (hours)
    
    let minutes = hoursToMinutes(hours);
     let splitMinutes =this.pd25Form.get('validUpToHrs').value.split('.')
     let minutes1 =parseInt(splitMinutes[1]);
     if(minutes1 > 59){
      this.pd25Form.get('validUpToHrs').setValue('')
     }
     if(minutes1 > 0){
      this.afterDecimal = minutes1
     }else{
      this.afterDecimal =0
     }
    this.pd25Form.patchValue({
      validUpto: moment().add((minutes + this.afterDecimal), 'minute')
    });
  }
  public captureScreen() {
    let payload = {
      applicationNumber: this.editObject.applicationNumber,
      id: this.editObject.id,
      //licenseNumber: this.editObject.licenseNumber,
      // entityType: this.editObject.entityType,
      receiptType: 'DISPATCH_OF_SPIRIT_NONREG_PD25'
    }
    this.NonRegService.downloadinspection(payload).subscribe(
      (resp: any) => fileSaver.saveAs(resp, this.editObject.applicationNumber.replace(/\//g, '_') + '_FL49_PD18'),
      (error: any) => this.alert.showError('Error downloading the file'),
      () => console.info('File downloaded successfully')
    );
  }
  public paymentReceipt() {
    let payload = {
      applicationNumber: this.editObject.applicationNumber,
      id: this.editObject.id,
      //licenseNumber: this.editObject.licenseNumber,
      // entityType: this.editObject.entityType,
      receiptType: 'NON_REGISTER_PAYMENT_RECEIPT'
    }
    this.NonRegService.downloadinspection(payload).subscribe(
      (resp: any) => fileSaver.saveAs(resp, this.editObject.applicationNumber.replace(/\//g, '_') + '_FL49_PD18'),
      (error: any) => this.alert.showError('Error downloading the file'),
      () => console.info('File downloaded successfully')
    );
  }
  numbersOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 9 || charCode > 18)
    ) {
      return false;
    }
    return true;
  }
   downloadDocument(uuid, documentName) {
      if (uuid && documentName) {
        this.documentUploadService.downloadDocument(uuid, documentName).subscribe((resp: any) => {
          fileSaver.saveAs(resp, documentName);
        },
          (error: any) => this.alert.showError('Error downloading the file', 'Error'),
          () => console.info('File downloaded successfully')
        );
      } else {
        this.alert.showError('File not available', 'Error');
      }
    }

    @ViewChild('exampleModalCenter1', { static: false }) exampleModalCenter1;
    imageboolean: boolean;
    imageDocName = ""
    imageSrcPath: any;
    async DownloadDoc(uuid, documentname) {
      let req = {
        "uuids": [
          uuid
        ]
      }
      this.imageSrcPath = '';
      this.imageboolean = true;
      const resp: any = await this.entPurchaseOrderService.postDownload(req);
      this.imageSrcPath = resp[0].bytes;
      this.imageboolean = true;
      this.imageDocName = documentname
      var fileFormat = this.imageDocName.split('.');
      var condition = fileFormat.length - 1;
      if (fileFormat[condition] === 'pdf') {
        this.imageboolean = true;
        const byteArray = new Uint8Array(
          atob(resp[0].bytes)
            .split("")
            .map(char => char.charCodeAt(0))
        );
        const file = new Blob([byteArray], { type: "application/pdf" });
        const url = URL.createObjectURL(file);
        document.querySelector('iframe').src = url + "#toolbar=0";
      }
      else {
        this.imageboolean = false;
      }
        this.exampleModalCenter1.show();
      
    }
  
}
function hoursToMinutes(hours: number) {
  return hours * 60;
}
