import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@appEnv/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class CommonApiServiceService {

  constructor(private http: HttpClient) { }
  public messageSource: BehaviorSubject<string> = new BehaviorSubject<string>('Initial Value');
  public messageSourceEDP :BehaviorSubject<string> = new BehaviorSubject<string>('Initial Value');
  public messageSourceview: BehaviorSubject<string> = new BehaviorSubject<string>('Initial Value');
  viewSelectedData = new EventEmitter();
  baseURL = environment.helpdeskURL;
  LicneseBaseUrl = environment.licneseBaseUrl
  payementBaseurl = environment.payandreconBaseUrl
  usermangmentBaseUrl = environment.userManagerUrl
  scmproductionUrl = environment.scmproduction
  baseURLMaster = environment.baseURLMaster
  retailUrl = environment.retailURL
  UploadBaseUrl = environment.documentUrl
  paymentDomainURL = environment.paymentDomain
  publicportalurl=environment.publicportalurl
  misportalurl=environment.misportalurl
  passValue(data: any) {
    this.messageSource.next(data);
  }
  passValueEDP(data: any) {
   return this.messageSourceEDP.next(data);
  }
  passValueViewEDP(data: any) {
    return this.messageSourceview.next(data);
   }
  getAllRjkosh(geturl): Observable<any> {
    const url = this.paymentDomainURL + geturl;
    return this.http.get(url);
  }
  postRjkosh(geturl,postdata): Observable<any> {
    const url = this.payementBaseurl + 'payandrecon/1.0/' + geturl;
    return this.http.post(url,postdata);
  }
  postpaymentDomain(apiurl, postdata): Observable<any> {
    const url = this.paymentDomainURL + apiurl;
    return this.http.post(url, postdata);
  }
  getAll(geturl): Observable<any> {
    const url = this.baseURL + geturl;
    return this.http.get(url);
  }
  getUserUrl(geturl) : Observable<any> {
    const url = this.usermangmentBaseUrl + geturl;
    return this.http.get(url);
  }
  downloadIndent(geturl): Observable<any> {
    const url = this.baseURL +  geturl; 
    return this.http.get(url, { responseType: 'blob' });
  }
  getTPpdf(geturl): Observable<any> {
    const url = geturl;
    return this.http.get(url);
  }
  PostScm(apiurl, postdata): Observable<any> {
    const url = this.scmproductionUrl + apiurl;
    return this.http.post(url, postdata);
  }
  getScm(apiurl): Observable<any> {
    const url = this.scmproductionUrl + apiurl;
    return this.http.get(url);
  }
  post(apiurl, postdata): Observable<any> {
    const url = this.baseURL + apiurl;
    return this.http.post(url, postdata);
  }
  getAllPayment(geturl): Observable<any> {
    const url = this.payementBaseurl + 'payandrecon/1.0/' + geturl;
    return this.http.get(url);
  }
  PdfDownloadPayment(geturl): Observable<any> {
    const url = this.payementBaseurl + 'payandrecon/1.0/' + geturl;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPut(geturl): Observable<any> {
    const url = this.baseURL + geturl;
    return this.http.put(url, '');
  }
  getAllMaster(geturl): Observable<any> {
    const url = this.baseURLMaster + geturl;
    return this.http.get(url);
  }
  postPayment(apiurl, postdata): Observable<any> {
    const url = this.payementBaseurl + 'payandrecon/1.0/' + apiurl;
    return this.http.post(url, postdata);
  }
  Put(apiurl, postdata): Observable<any> {
    const url = this.baseURL + apiurl;
    return this.http.put(url, postdata);
  }

  PutPayment(apiurl, postdata): Observable<any> {
    const url = this.payementBaseurl + 'payandrecon/1.0/' + apiurl;
    return this.http.put(url, postdata);
  }
  getLicense(geturl): Observable<any> {
    const url = this.LicneseBaseUrl + geturl;
    return this.http.post(url, '');
  }
  PostLicense(geturl, req): Observable<any> {
    const url = this.LicneseBaseUrl + geturl;
    return this.http.post(url, req);
  }
  getAllLicense(geturl): Observable<any> {
    const url = this.LicneseBaseUrl + geturl;
    return this.http.get(url);
  }
  postRetail(apiurl, postdata): Observable<any> {
    const url = this.retailUrl + apiurl;
    return this.http.post(url, postdata);
  }
  getUser(geturl): Observable<any> {
    const url = this.usermangmentBaseUrl + geturl;

    return this.http.post(url, '');
  }
  getUserAll(geturl): Observable<any> {
    const url = this.usermangmentBaseUrl + geturl;

    return this.http.get(url);
  }
  getlicense(geturl): Observable<any> {
    const url = this.usermangmentBaseUrl + geturl;

    return this.http.get(url);
  }

  uploadDocuments(payload): Observable<any> {
    const url = this.UploadBaseUrl + 'api/document/uploadDocumentByVersionNoToken'
    return this.http.post(url, payload)
  }
  postDownload(apiurl, postdata): any {
    const url = environment.documentUrl + apiurl;
    return this.http.post(url, postdata).toPromise();
  }

  getLicenseType(licenseEntityCode) {
    const url = this.usermangmentBaseUrl + apiUrls.getLicenseType + licenseEntityCode;
    return this.http.get(url);
  }
  getpublicportalurl(){
    let value={
      publicportalurl:environment.publicportalurl ,
      misportalurl:environment.misportalurl 
    }
    return value
  } 
}
