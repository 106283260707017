<h5 class="m-b-10">
    View Accidental Request
</h5>

<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:"><i class="feather icon-home"></i></a></li>
    <!-- <li class="breadcrumb-item" *ngIf="entityType == 'SUGAR_MILL'"><a href="javascript:">
        {{'language.sugarmill' | translate}} </a></li> -->
    <li class="breadcrumb-item" *ngIf="accidentRqstObj && accidentRqstObj.licenseSubCategory && accidentRqstObj.licenseSubCategory=='INDUSTRY_INSIDE_UP'"><a href="javascript:">Industries Inside UP</a></li>
    <li class="breadcrumb-item" *ngIf="accidentRqstObj && accidentRqstObj.licenseSubCategory && accidentRqstObj.licenseSubCategory=='INDUSTRY_OUTSIDE_UP'"><a href="javascript:">Industries Outside UP</a></li>
    <li class="breadcrumb-item" *ngIf="!(accidentRqstObj && accidentRqstObj.licenseSubCategory && (accidentRqstObj.licenseSubCategory=='INDUSTRY_INSIDE_UP' || accidentRqstObj.licenseSubCategory=='INDUSTRY_OUTSIDE_UP'))"><a href="javascript:">{{'language.sugarmill'|translate}}</a></li>
    <li class="breadcrumb-item"><a href="javascript:">Accidental Request</a></li>
    <li class="breadcrumb-item">List</li>
</ul>
<div class="row mb-2">
    <div class=" col-12 col-sm-8 ">
    </div>
    <div class="  col-sm-4 col-12 text-right">
        <div class="qrcodeImage" style="min-height:100px">
            <qrcode #parent [qrdata]="accidentRqstObj?.mf4GatePassNo" [allowEmptyString]="true"
                [alt]="accidentRqstObj?.mf4GatePassNo" [cssClass]="'centerqr'" [colorDark]="'#000000ff'"
                [colorLight]="'#ffffffff'" [elementType]="'img'" [errorCorrectionLevel]="'M'" [margin]="4" [scale]="1"
                [title]="'MF-4 Gate-PassNo'" [width]="100"></qrcode>


        </div>
    </div>
</div>
<div class="row" id="contentToConvert">
    <div class="col-xl-12">
        <app-card cardTitle="Unit Details" class="list-panel view-panel">
            <div class="row mb-2 pb-2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'mf1.view.financialYear' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">2022-2023</div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <label class="w-100 view-label">License Type<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">{{accidentRqstObj?.licenseType}}</div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <label class="w-100 view-label">License Number<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">{{accidentRqstObj?.licenseNumber}}</div>
                <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"><label
                        class="w-100 view-label">{{'mf1.view.unitName' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">{{ bottlingUnitAddress?.unitName }}</div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"><label
                        class="w-100 view-label">{{'mf1.view.unitAddress' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{ bottlingUnitAddress?.address }}
                </div> -->
            </div>
        </app-card>
        <app-card cardTitle="{{'language.applicationDetails' | translate}}" class="list-panel view-panel">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.requestDate' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.requestDate | date:'dd-MM-yyyy'}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.mf4gatepassno' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.mf4GatePassNo}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.transportpasstype' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    MF4
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.applicationdate' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.applicationDate }}</div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Sugar Mill Name<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.sugarMillName }}</div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Distillery Name<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.distilleryName}}</div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Quantity<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.quantity}}</div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Grade<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.grade}}</div>
            </div>
        </app-card>
        <app-card cardTitle="{{'language.accidentallocationaddress' | translate}}" class="list-panel view-panel">
            <div class="row">

                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.state' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.accidentalTypeStateValue}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.district' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.accidentalTypeDistrictValue}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.tehsil' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{tehsilValue}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.urban/rural' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.urbanOrRuralValue}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6"
                    *ngIf="accidentRqstObj?.urbanOrRuralValue == 'Urban'">
                    <label class="w-100 view-label">{{'licenseMgnt.municipalityText' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6"
                    *ngIf="accidentRqstObj?.urbanOrRuralValue == 'Urban'">
                    {{accidentRqstObj?.municipalAreaCode}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6"
                    *ngIf="accidentRqstObj?.urbanOrRuralValue == 'Urban'">
                    <label class="w-100 view-label">{{'licenseMgnt.wardText' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6"
                    *ngIf="accidentRqstObj?.urbanOrRuralValue == 'Urban'">
                    {{accidentRqstObj?.ward}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.policestation' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.policeStation}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.locality' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.locality}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.street' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.street}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.pincode' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.pinCode}}
                </div>
            </div>
        </app-card>
        <app-card cardTitle="{{'language.accidentaldetails' | translate}}" class="list-panel view-panel">
            <div class="row">

                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.accidentaltype' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.accidentalTypeValue}}
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'language.accidentdate' | translate}}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.accidentDate}}
                </div>
                <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Fine Amount<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.fees}}
                </div> -->
            </div>
        </app-card>
        <ng-container *ngIf="accidentType =='vehicleDamagedButProductGoodDetails'">
            <app-card cardTitle="{{'language.vehicledamagedproductgood' | translate}}" class="list-panel view-panel">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Mode Of Transport<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.modeOfTransport}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport">
                        <label class="w-100 view-label">Start Reading<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.startReading}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport">
                        <label class="w-100 view-label">End Reading<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.endReading}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.doyouwantchangevehicledtls' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.changeTheVehicleDetails}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.transportername' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.transporterName}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.vehicleno' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.vehicleNo}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.vehicledrivername' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.vehicleDriverName}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.routetype' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.routeType}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.routedetails' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.routeDetails}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.majorroute' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.majorRoute}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.gpsid' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.gpsId}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" >
                        <label class="w-100 view-label">{{'language.doyouwantextendtranspassvalidity' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.extendTransportPassValidity}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.remainingkms' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.remainingKms}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'language.validupto' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{accidentRqstObj.vehicleDamagedButProductGoodDetails.extendTransportPassValidity}}
                    </div>
                </div>
            </app-card>
        </ng-container>

        <ng-container *ngIf="accidentType =='productDamagedButVehicleGoodDetails'">
            <app-card cardTitle="Product Damaged but Vehicle good" class="list-panel view-panel">
                <div class="row">
                    <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Scan Available Barcode/QR code<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        -
                    </div> -->
                    <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Total Quantity of Molasses (Qtls)<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{accidentRqstObj.productDamagedButVehicleGoodDetails.damagedQuantityOfMolasses}}
                    </div> -->
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Damaged Quantity of Molasses (Qtls)<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{accidentRqstObj.productDamagedButVehicleGoodDetails.totalQuantityOfMolasses}}
                    </div>

                </div>
            </app-card>
        </ng-container>

        <ng-container *ngIf="accidentType =='productAndVehicleBothDamagedDetails'">
            <app-card cardTitle="{{'language.bothdamaged' | translate}}" class="list-panel view-panel">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Mode Of Transport<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{accidentRqstObj.productAndVehicleBothDamagedDetails.modeOfTransport}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport">
                        <label class="w-100 view-label">Start Reading<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport">
                        {{accidentRqstObj.productAndVehicleBothDamagedDetails.startReading}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport">
                        <label class="w-100 view-label">End Reading<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport">
                        {{accidentRqstObj.productAndVehicleBothDamagedDetails.endReading}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.transportername' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.productAndVehicleBothDamagedDetails.transporterName}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.vehicleno' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.productAndVehicleBothDamagedDetails.vehicleNo}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.vehicledrivername' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.productAndVehicleBothDamagedDetails.vehicleDriverName}}
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.routedetails' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.productAndVehicleBothDamagedDetails.routeDetails}}
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        <label class="w-100 view-label">{{'language.gpsid' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6" *ngIf="isTransport==false">
                        {{accidentRqstObj.productAndVehicleBothDamagedDetails.gpsId}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Do you want to send back?<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{accidentRqstObj.productAndVehicleBothDamagedDetails.wantToSendBack}}
                    </div>

                </div>
            </app-card>
        </ng-container>

        <app-card cardTitle="Upload Documents and Wastage" class="list-panel view-panel">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                            *ngFor="let i of uploaddata let i = index">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <label class="w-100 view-label">{{i.name}}<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <span class="download-text">{{i.documentName}} <button
                                            class="btn btn-icon btn-primary mb-2" type="button"
                                            (click)="downloadDocument(i.uuid,i.documentName)">
                                            <i class="feather icon-download"></i></button></span>
                                    <span class="download-text ml-1"><button class="btn btn-icon btn-primary mb-2"
                                            type="button" (click)="DownloadDoc(i.uuid,i.documentName)">
                                            <i class="feather icon-eye"></i></button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Wastage of molasses<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{accidentRqstObj?.molassesWastage}}
                </div>
            </div>

        </app-card>

    </div>
 <div class="col-xl-12">
        <form autocomplete="off" [formGroup]="brandForm">
            <!-- <app-card cardTitle="Penalty Details" class="list-panel view-panel" *ngIf="isCustomer == 'DEO' && alreadyPayment">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Fine to be Imposted</label>
                            <div class="segment">
                                <input type="radio" name="FineImposted" id="tab1" value="Yes"
                                    formControlName="FineImposted" (change)="getFineAmount($event.target.value)">
                                <label class="" for="tab1">{{'language.yes' | translate}}</label>
                                <input type="radio" name="FineImposted" id="tab2" value="No"
                                    formControlName="FineImposted" (change)="getFineAmount($event.target.value)">
                                <label class="" for="tab2">{{'language.no' | translate}}</label>
                            </div>
                        </div><br>
                        <div *ngIf="formSubmitted && brandForm.get('FineImposted').errors || brandForm.get('FineImposted').touched "
                            class="error-msg">
                            <span *ngIf="brandForm.get('FineImposted').errors?.required"> Fine to be Imposed is
                                required </span>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="isPaymentEnable">
                        <div class="form-group">
                            <label class="mandatory">Fine Type</label>
                            <div class="segment">
                                <input type="radio" name="fineType" id="tab3" value="Yes" formControlName="fineType"
                                (change)="getFineLimit($event.target.value)">
                                <label class="" for="tab3">Penalty</label>
                                <input type="radio" name="fineType" id="tab4" value="No" formControlName="fineType"
                                (change)="getFineLimit($event.target.value)">
                                <label class="" for="tab4">compounding</label>
                            </div>
                        </div><br>
                        <div *ngIf="formSubmitted && brandForm.get('fineType').errors || brandForm.get('fineType').touched "
                        class="error-msg">
                        <span *ngIf="brandForm.get('fineType').errors?.required">Fine Type is
                            required </span>
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12" *ngIf="isPaymentEnable">
                        <div class="form-group">
                            <label class="mandatory">Fine Amount (Rs.)</label>
                            <input type="text" appTwoDigitDecimaNumber maxlength="10" class="form-control" formControlName="fees" placeholder="Enter the Fees">
                        </div>
                        <div *ngIf="formSubmitted && brandForm.get('fees').errors || brandForm.get('fees').touched "
                        class="error-msg">
                        <span *ngIf="brandForm.get('fees').errors?.required"> Fees is
                            required </span>
                            <span *ngIf="brandForm.get('fees').errors?.min">Penalty Fees should be greater than 0</span>
                            <span *ngIf="brandForm.get('fees').hasError('max') && brandForm.get('fineType').value == 'Yes'">Penalty Fees should be less than 50000</span>
                            <span *ngIf="brandForm.get('fees').hasError('max') && brandForm.get('fineType').value == 'No'"> compounding Fees should be less than 100000</span>
                    </div>
                    </div>
                </div>
            </app-card> -->
            <app-card *ngIf="logactive" cardTitle="Logs" blockClass="table-border-style" class="search-panel">
                <div class="table-responsive">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr>
                                <th>{{'parentunit.intimationForm.se_no' | translate }}</th>
                                <th>{{'brewery.common.date' | translate }}</th>
                                <th>{{'brewery.common.actionperformedby' | translate }}</th>
                                <th>{{'brewery.common.designation' | translate }}</th>
                                <th>{{'brewery.common.action' | translate }}</th>
                                <th>{{'brewery.common.comments' | translate }}</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let logs of logshow;let i=index'>
                                <td>{{i+1}}</td>
                                <td>{{logs?.actionTime | date:"dd/MM/yyyy HH:mm:ss":"+0000"}}</td>
                                <td>{{logs?.actionPerformedBy}}</td>
                                <td>{{logs?.designation}}</td>
                                <td>{{logs?.action}}</td>
                                <td>{{logs?.comments}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </app-card>


            <app-card *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isEditable" cardTitle="Remarks"
                class="list-panel add-panel">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'brewery.common.remarks' | translate }}
                            </label>
                            <input type="text" class="form-control" placeholder="Enter Remarks"
                                formControlName="remark">
                            <div *ngIf="formSubmitted && (brandform.remark.errors || brandform.remark.dirty || brandform.remark.touched)"
                                class="error-msg">

                                <span *ngIf="formSubmitted && brandform.remark?.errors?.required">
                                    {{'brewery.common.remarks' | translate }} {{'master.common.isrequired' | translate}}
                                    <br>
                                </span>
                                <span *ngIf="brandform.remark?.errors?.maxlength">
                                    {{'brewery.common.remarks' | translate }} must be less than 100 characters
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>

            <div class="col-5" *ngIf="isPaymentEnable == false">
                <div class="d-flex">
                    <div class="flex-fill">
                        <select class="form-control" formControlName="sendBackLevel"
                            *ngIf='accidentRqstObj?.isShowButtons && workflowdata?.isSendBack'>
                            <option value="">{{'brewery.common.select' | translate }}</option>
                            <option value="{{ sebd.key}}" *ngFor='let sebd of workflowdata?.sendBackList'>{{
                                sebd.value}}
                            </option>
                        </select>
                        <div *ngIf="formSubmitted && (brandform.sendBackLevel.errors || brandform.sendBackLevel.dirty || brandform.sendBackLevel.touched)"
                            class="error-msg">
                            <div *ngIf="brandform.sendBackLevel?.errors?.required"><i class="fa fa-times"></i>
                                Dropdown Value Is required </div>
                        </div>
                    </div>
                    <div class="flex-fill">
                        <button type="button" class="btn btn-warning ml-2 mb-2"
                            (click)="showConfirmation('SENDBACK', brandForm)" [disabled]="isClarificationLoading"
                            *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isSendBack">
                            <i class="fa"
                                [ngClass]="{'fa-spin fa-spinner': isClarificationLoading, 'fa-check': !isClarificationLoading}"></i>
                            Request for Clarification
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-5" *ngIf="isCustomer != 'DEO'">
                <div class="d-flex">
                    <div class="flex-fill">
                        <select class="form-control" formControlName="sendBackLevel"
                            *ngIf='accidentRqstObj?.isShowButtons && workflowdata?.isSendBack'>
                            <option value="">{{'brewery.common.select' | translate }}</option>
                            <option value="{{ sebd.key}}" *ngFor='let sebd of workflowdata?.sendBackList'>{{
                                sebd.value}}
                            </option>
                        </select>
                        <div *ngIf="formSubmitted && (brandform.sendBackLevel.errors || brandform.sendBackLevel.dirty || brandform.sendBackLevel.touched)"
                            class="error-msg">
                            <div *ngIf="brandform.sendBackLevel?.errors?.required"><i class="fa fa-times"></i>
                                Dropdown Value Is required </div>
                        </div>
                    </div>
                    <div class="flex-fill">
                        <button type="button" class="btn btn-warning ml-2 mb-2"
                            (click)="showConfirmation('SENDBACK', brandForm)" [disabled]="isClarificationLoading"
                            *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isSendBack">
                            <i class="fa"
                                [ngClass]="{'fa-spin fa-spinner': isClarificationLoading, 'fa-check': !isClarificationLoading}"></i>
                            Request for Clarification
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-5" *ngIf="isCustomer == 'DEO' && alreadyPayment == false">
                <div class="d-flex">
                    <div class="flex-fill">
                        <select class="form-control" formControlName="sendBackLevel"
                            *ngIf='accidentRqstObj?.isShowButtons && workflowdata?.isSendBack'>
                            <option value="">{{'brewery.common.select' | translate }}</option>
                            <option value="{{ sebd.key}}" *ngFor='let sebd of workflowdata?.sendBackList'>{{
                                sebd.value}}
                            </option>
                        </select>
                        <div *ngIf="formSubmitted && (brandform.sendBackLevel.errors || brandform.sendBackLevel.dirty || brandform.sendBackLevel.touched)"
                            class="error-msg">
                            <div *ngIf="brandform.sendBackLevel?.errors?.required"><i class="fa fa-times"></i>
                                Dropdown Value Is required </div>
                        </div>
                    </div>
                    <div class="flex-fill">
                        <button type="button" class="btn btn-warning ml-2 mb-2"
                            (click)="showConfirmation('SENDBACK', brandForm)" [disabled]="isClarificationLoading"
                            *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isSendBack">
                            <i class="fa"
                                [ngClass]="{'fa-spin fa-spinner': isClarificationLoading, 'fa-check': !isClarificationLoading}"></i>
                            Request for Clarification
                        </button>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <div class="col-12 text-right mb-4">
        <!-- <button type="submit" class="btn btn-success mb-2 ml-2">Approve</button>
        <button type="submit" class="btn btn-danger mb-2 ml-2">Reject</button>
        <button type="submit" class="btn btn-warning mb-2 ml-2">Request for Clarification</button>
        <button type="submit" class="btn btn-primary mb-2 ml-2">{{'bondWarehouse.common.print' | translate}} </button> -->
        <button type="button" class="btn btn-success mb-2 ml-2"
            (click)="showConfirmation('ACCIDENTAL_REQUEST_FEE', brandForm)" [disabled]="isPaymentLoading"
            *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isEditable && isCustomer == 'DEO' && isPaymentEnable && alreadyPayment">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isPaymentLoading, 'fa-check': !isPaymentLoading}"></i>
            Forward to Payment of Penalty</button>
        <button type="button" class="btn btn-success mb-2 ml-2" (click)="showConfirmation('APPROVED', brandForm)"
            [disabled]="isApprovedLoading"
            *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isEditable && isPaymentEnable == false">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isApprovedLoading, 'fa-check': !isApprovedLoading}"></i>
            {{ 'brewery.common.approve' | translate }}</button>
        <button type="button" class="btn btn-success mb-2 ml-2" (click)="showConfirmation('APPROVED', brandForm)"
            [disabled]="isApprovedLoading"
            *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isEditable && isCustomer != 'DEO'">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isApprovedLoading, 'fa-check': !isApprovedLoading}"></i>
            {{ 'brewery.common.approve' | translate }}</button>
            <button type="button" class="btn btn-success mb-2 ml-2" (click)="showConfirmation('APPROVED', brandForm)"
            [disabled]="isApprovedLoading"
            *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isEditable && isCustomer == 'DEO' && alreadyPayment == false">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isApprovedLoading, 'fa-check': !isApprovedLoading}"></i>
            {{ 'brewery.common.approve' | translate }}</button>
        <button type="button" class="btn btn-danger mb-2 ml-2" (click)="showConfirmation('REJECTED', brandForm)"
            [disabled]="isRejectLoading"
            *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isRejected && isPaymentEnable == false">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isRejectLoading, 'fa-check': !isRejectLoading}"></i>
            {{ 'brewery.common.reject' | translate }}</button>
        <button type="button" class="btn btn-danger mb-2 ml-2" (click)="showConfirmation('REJECTED', brandForm)"
            [disabled]="isRejectLoading"
            *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isRejected && isCustomer != 'DEO'">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isRejectLoading, 'fa-check': !isRejectLoading}"></i>
            {{ 'brewery.common.reject' | translate }}</button>
            <button type="button" class="btn btn-danger mb-2 ml-2" (click)="showConfirmation('REJECTED', brandForm)"
            [disabled]="isRejectLoading"
            *ngIf="(accidentRqstObj?.isShowButtons) && workflowdata?.isRejected && isCustomer == 'DEO' && alreadyPayment == false">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isRejectLoading, 'fa-check': !isRejectLoading}"></i>
            {{ 'brewery.common.reject' | translate }}</button>
        <!-- <div class="col-12 text-right mb-4"> -->
        <!-- <button  type="button" class="btn btn-primary ml-2 mb-2" (click)="generatePdf()">
                Download</button> -->
        <!-- <button type="submit" class="btn btn-success mb-2 ml-2" (click)="downloadRecipt()"
            *ngIf="isCustomer && accidentRqstObj?.status=='APPROVED'">
            Download Receipt</button> -->
            <button type="submit" class="btn btn-info ml-2"  title="View Signed Document"  *ngIf="signeddocument"  
        (click)="ViewDoc(signeddocument.uuid,signeddocument.documentName)">
            View  <i class="feather icon-eye"></i></button>
        <button type="submit" class="btn btn-info ml-2" *ngIf="signeddocument"
            (click)="downloadDocument(signeddocument.uuid,signeddocument.documentName)">
            Download Signed Document</button>
            <button type="submit" class="btn btn-success  ml-2" (click)="captureScreen()"
            *ngIf="accidentRqstObj?.status == 'APPROVED'">{{ 'language.download' | translate }}</button>
        <button *ngIf="!isPreview" type="button" class="btn btn-secondary ml-2 mb-2"
            routerLink="/sugarmill/{{outsideupvalidation}}/list">
            {{ 'sugarmill.common.back' | translate }}</button>

        <button *ngIf="isPreview" type="button" class="btn btn-secondary ml-2 mb-2" (click)="closes()">
            {{'sugarmill.common.back'|translate}}</button>
        <!-- </div> -->
    </div>
</div>

<app-ui-modal #exampleModalCenter dialogClass="modal-dialog-centered" (click)="close()">
    <div class="app-modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="close()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <h6 class="text-secondary text-center" *ngIf="confirmStyatus == 'APPROVED'">
            Are you sure you want to Approve?
        </h6>
        <h6 class="text-secondary text-center" *ngIf="confirmStyatus == 'REJECTED'">
            Are you sure you want to Reject?
        </h6>
        <h6 class="text-secondary text-center" *ngIf="confirmStyatus == 'SENDBACK'">
            Are you sure you want to Request for Clarification?
        </h6>
        <h6 class="text-secondary text-center" *ngIf="confirmStyatus == 'ACCIDENTAL_REQUEST_FEE'">
            Are you sure you want to pay penalty?
        </h6>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" (click)="feedback(confirmStyatus)">Yes</button>
        <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal" (click)="close()">No</button>
    </div>
</app-ui-modal>
<app-ui-modal #exampleModalCenter1 dialogClass="modal-dialog-centered   modal-xl">
    <div class="app-modal-body text-center" style="padding-top: 20px;">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="exampleModalCenter1.hide()"><span aria-hidden="true">&times;</span></button>
        <img *ngIf="!imageboolean" src="data:image/jpeg;base64,{{imageSrcPath}}"
            style="width:100%; object-fit: contain;height:600px" />
        <div *ngIf="imageboolean === true">
            <iframe style="width:100%;height:600px;" target="_blank" frameborder="no"></iframe>
        </div>
        <h3>{{imageDocName}}</h3>
    </div>
</app-ui-modal>
<!-- <div class="col-12  mb-3">
    <ngb-accordion [closeOthers]="true" activeIds="static-2" class="next-accordion">
        <ngb-panel id="static-2">
            <ng-template ngbPanelTitle>
                <h5>Logs</h5>
            </ng-template>
            <ng-template ngbPanelContent>
<form>
<div class="row mx-4 timeline_card" >
<div class="col-12">
<div class="timeline_icon text-center timeline_icon_bg1 h-100">
<i class="far fa-comments"></i>
<small>20/03/21<br> 10:00</small>
</div>
<div class="timeline_content">
<div class="w-100 p-3 rounded timeline_box timeline_bg1">
<h6>DEO - Sunil Singh</h6>
<p class="mb-0">Need Clarification</p>
</div>
</div>
</div>
<div class="col-12">
<div class="timeline_icon text-center timeline_icon_bg3 h-100">
<i class="fas fa-check"></i>
<small>20/03/21<br> 11:00</small>
</div>
<div class="timeline_content">
<div class="w-100 p-3 rounded timeline_box timeline_bg2">
<h6>Wholesale - Good Wines</h6>
<p class="mb-0">Accepted</p>
</div>
</div>
</div>
<div class="col-12">
<div class="timeline_icon text-center timeline_icon_bg2 h-100">
    <i class="fas fa-check"></i>
<small>20/03/21<br> 11:30</small>
</div>
<div class="timeline_content">
<div class="w-100 p-3 rounded timeline_box timeline_bg3">
    <h6>Wholesale - Good Wines</h6>
<p class="mb-0">Transport Pass Clarification updated. Kindly check</p>
</div>
</div>
</div>
</div>
</form>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div> -->

<!-- <div class="row">
    <div class="col-12">
        <app-card cardTitle="Request for Accidental Case" class="list-panel add-panel">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Request Date<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    20-Jun-2021</div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Request ID<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        AP1122
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Distillery Name<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        Name
                    </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Gate Pass Type<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                 Type 1
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Gate Pass Number<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                 GP001
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Gate Pass Date<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                 27-09-2021
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Accident Location Address<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    33, Road no.2, Phase 3, Gomti nagar, Ward no. 3, Mohanlalganj, Lucknow, Uttar Pradesh, 225010, INDIA
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Accident Location District <span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                   District
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Accident Date<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                   20-Jul-2021
                </div> 
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Accident Type<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                   Medium
                </div> 
                 <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Accident Location<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                   Lucknow
                </div> 
            
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Status<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                   Approved
                </div> 
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Approved By<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                   Sunil
                </div> 
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">Approved on<span class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                   20-Jul-2021
                </div> 
            </div>
        </app-card>
        
    </div>
    
    <div class="col-12 text-right mb-4">
        <button type="button" class="btn btn-secondary ml-2"
            routerLink="/supplychainmanagement/wholesale/accidentalrequest/list">
            Back</button>
    </div>    
</div> -->
