<h5 class="m-b-10 ng-star-inserted"> {{'master.common.add' | translate}} Dispatch Of Spirit to Non Register User PD 25</h5>
<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:" [routerLink]="['/welcome']"><i class="feather icon-home"></i></a>
    </li>
    <li class="breadcrumb-item" *ngIf="entityType == 'PD'"><a>{{'otProductionPlan.list.production' |
            translate}}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'ID'"><a>{{'otProductionPlan.list.industrialDistillery' |
            translate}}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'MIXED'"><a>Mixed
            {{'otProductionPlan.list.distillery'| translate}} </a></li>
    <li class="breadcrumb-item"><a href="javascript:" routerLink="/{{url}}/nonregpd25/list">Dispatch Of Spirit to Non Register User PD 25</a></li>
    <li class="breadcrumb-item"><a href="javascript:">{{'master.common.add' | translate}} </a></li>
</ul>
<div class="row">
    <div class="col-12">
<form [formGroup]="pd25Form" autocomplete="off">
  
            <app-card cardTitle="Dispatch Details" class="list-panel add-panel">
                <div class="row">
                    
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'parentunit.stockActivityList.appDate' |
                                translate}}</label>
                            <input type="text" name="dateOfApplication" disabled formControlName='dateOfApplication'
                                class="form-control" value=" {{myDate | date:'dd/MM/yyyy'}}">

                        </div>
                    </div>
                </div>
            </app-card>
            <app-card cardTitle="Consignor Details" class="list-panel add-panel">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'molassesDC.list.dName' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <!-- {{item.unitName}} -->
                        {{licenseDetailsResponse?.unitName}}
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'molassesDC.list.licenseNumber' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        {{licenseDetailsResponse?.licenseNumber}}
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'molassesDC.add.dAddr' | translate}}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        {{licenseDetailsResponse?.address}}
                        <!-- {{selectedApplicationNo?.consigneeBottlingAddress}} -->
                    </div>
                </div>
            </app-card>
            <app-card cardTitle="{{'blendTransferReq.add.consigneeDetails' | translate}}" class="list-panel add-panel">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Entity Type</label>
                            <input type="text" class="form-control" formControlName='consigneeEntityType' name="consigneeEntityType"
                                placeholder="Enter Entity Type">
                            <app-show-errors eleName="Entity Type" [frmSub]="formSubmitted"
                                [control]="getFormCntr('consigneeEntityType')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Entity Name</label>
                            <input type="text" class="form-control" formControlName='consigneeEntityName'
                                name="consigneeEntityName" placeholder="Enter Entity Name">
                            <app-show-errors eleName="Entity Name" [frmSub]="formSubmitted"
                                [control]="getFormCntr('consigneeEntityName')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Entity Register Number</label>
                            <input type="text" class="form-control" formControlName='consigneeRegNo'
                                name="consigneeRegNo" placeholder="Enter Entity Register Number">
                            <app-show-errors eleName="Entity Register Number" [frmSub]="formSubmitted"
                                [control]="getFormCntr('consigneeRegNo')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">PAN No</label>
                            <input type="text" class="form-control" formControlName='consigneePanNo'
                                name="consigneePanNo" placeholder="Enter PAN No">
                            <app-show-errors eleName="PAN No" [frmSub]="formSubmitted"
                                [control]="getFormCntr('consigneePanNo')">
                            </app-show-errors>
                        </div>
                    </div>
                </div>
            </app-card>
            <!-- <div class="col-xl-12"> -->
            <app-card cardTitle="Permit Details" class="list-panel add-panel">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Permit No</label>
                            <input type="text" class="form-control" formControlName='permitNo' name="permitNo"
                                placeholder="Enter Permit No">
                            <app-show-errors eleName="Permit No" [frmSub]="formSubmitted"
                                [control]="getFormCntr('permitNo')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Permit Date</label>
                            <input type="date" class="form-control" name="permitDate" [min]="minPermitDate"
                                [max]="maxPermitDate" formControlName="permitDate" placeholder="Enter Permit Date">
                            <app-show-errors eleName="Permit Date" [frmSub]="formSubmitted"
                                [control]="getFormCntr('permitDate')">
                            </app-show-errors>
                        </div>
                    </div>
                     <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Spirit Type</label>
                             <input type="text" class="form-control" formControlName='spiritType' name="spiritType"
                                placeholder="Enter Spirit Type">
                            <app-show-errors eleName="Spirit Type" [frmSub]="formSubmitted" [control]="getFormCntr('spiritType')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Permitted Quantity</label>
                            <input type="text" class="form-control" appTwoDigitDecimaNumber formControlName='permitQty' name="permitQty"
                                placeholder="Enter Permit Quantity">
                            <app-show-errors eleName="Permitted Quantity" [frmSub]="formSubmitted"
                                [control]="getFormCntr('permitQty')">
                            </app-show-errors>
                        </div>
                    </div> 
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Issued Quantity</label>
                            <input type="text" class="form-control" appTwoDigitDecimaNumber formControlName='issueQty'
                                name="issueQty" maxlength="12" placeholder="Enter Issued Quantity">
                            <app-show-errors eleName="Issued Quantity" [frmSub]="formSubmitted"
                                [control]="getFormCntr('issueQty')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Advolaram Fees</label>
                            <input type="text" class="form-control" appTwoDigitDecimaNumber formControlName='advolaramFee'
                                name="advolaramFee" maxlength="12" placeholder="Enter Advolaram Fees">
                            <app-show-errors eleName="Advolaram Fees" [frmSub]="formSubmitted"
                                [control]="getFormCntr('advolaramFee')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory"
                                for="uploadpuc">Upload Permit Copy<span class="text-primary">&#42;</span>
                            </label>
                            <div class="input-group cust-file-button">
                                <div class="custom-file">
                                    <input type="file" id="customFilessss" class="custom-file-input" formControlName='permitCopyDoc'
                                        accept="image/png,image/jpeg,image/jpg,application/pdf,image/x-png"
                                        (change)="onFileChange($event, 'permitCopyDocacahmentFiles', pd25Form, 'permitCopyDoc')">
                                    <label class="custom-file-label"></label>
                                    <label class="custom-file-label" for="customFilessss"></label>
                                </div>
                            </div>
                            <app-show-errors eleName="{{'language.attachments' | translate}}" 
                                [frmSub]="formSubmitted" [control]="getFormCntr('permitCopyDoc')">
                            </app-show-errors>
                            <div *ngIf = "editObject?.nonRegisterPD25DocList[0]?.documentName">
                                {{editObject?.nonRegisterPD25DocList[0]?.documentName}}
                                <button class="btn btn-primary"
                                    (click)="downloadDocument(getDocName('nonRegisterPD25DocList[0]').uuid,getDocName('nonRegisterPD25DocList[0]').documentName)">
                                    <i class="feather icon-download"></i>
                                </button>
                            </div>
                            <div class="error-msg" *ngIf="getFormCntr('permitCopyDoc').errors?.requiredFileType">
                                <span>
                                    <i class="fa fa-times"></i> File type must be pdf,png,jpeg,jpg,x-png
                                </span>
                            </div>
                            <div class="error-msg" *ngIf="getFormCntr('permitCopyDoc').errors?.fileSizeValidator">
                                <span>
                                    <i class="fa fa-times"></i> Please upload less than or equal to 2 Mb size
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>
            <app-card cardTitle="Tank Details" class="list-panel add-panel">
                <div class="col-12 mb-2">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center mb-0">
                            <thead>
                                <tr>
                                    <th>{{'parentunit.intimationForm.se_no' | translate}}</th>
                                    <th><span class="mandatory">Tank Name</span></th>
                                    <th><span class="mandatory">Strength(%v/v)</span> </th>
                                    <th>Available Quantity(BL) </th>
                                    <th>Available Quantity(AL) </th>
                                    <th><span class="mandatory">Transfer Quantity(BL)</span></th>
                                    <th>Transfer Quantity(AL)</th>
                                    <th><span class="mandatory">Wastage(BL)</span></th>
                                    <th>Wastage(AL)</th>
                                    <th>Remaining Quantity(BL)</th>
                                    <th>Remaining Quantity(AL)</th>
                                    <th> {{'master.common.action' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr formArrayName="transferDetails"
                                    *ngFor="let pres of pd25Form.get('transferDetails')['controls']; let j=index"
                                    id="row{{i}}">
                                    <td [formGroupName]="j">{{j+1}}</td>
                                    <td [formGroupName]="j">
                                        <select class="form-control" required formControlName="tankNameCode"
                                            (change)="getTankDetails($event.target.value, j)">
                                            <option value="">{{'master.common.select' | translate}}</option>
                                            <option *ngFor="let alltank of tankDrumList" value="{{alltank?.tankId}}">
                                                {{alltank?.tankName}} ({{alltank?.tankId}})
                                            </option>
                                        </select>
                                        <app-show-errors eleName="Tank Name" [frmSub]="formSubmitted"
                                            [control]="getFormCntr1(j,'tankNameCode')">
                                        </app-show-errors>
                                        <!-- <input type="text" class="form-control" formControlName="tankNameValue"
                                            placeholder="{{'master.common.enter' | translate}} " hidden> -->
                                    </td>
                                    <td [formGroupName]="j">
                                        <input type="text" class="form-control" formControlName="strength"
                                            appTwoDigitDecimaNumber (keyup)="calculateAvailableQuantityAL(j)"
                                            placeholder="{{'master.common.enter' | translate}} Strength(%v/v)"
                                            (keypress)="checkLength($event.target.value,13)" maxlength="5">
                                        <app-show-errors eleName="Strength" [frmSub]="formSubmitted"
                                            [control]="getFormCntr1(j,'strength')">
                                        </app-show-errors>
                                    </td>
                                    <td [formGroupName]="j">
                                        <input type="text" class="form-control" formControlName="availableQuantityBl"
                                            appThreeDigitDecimaNumber
                                            placeholder="{{'master.common.enter' | translate}} Available Quantity(BL)"
                                            (keypress)="checkLength($event.target.value,13)" disabled>
                                    </td>
                                    <td [formGroupName]="j">
                                        <input type="text" class="form-control" formControlName="availableQuantityAl"
                                            appThreeDigitDecimaNumber
                                            placeholder="{{'master.common.enter' | translate}} Available Quantity(AL)"
                                            (keypress)="checkLength($event.target.value,13)" disabled>
                                    </td>
                                    <td [formGroupName]="j">
                                        <input type="text" class="form-control" formControlName="transferQuantityBl"
                                            appThreeDigitDecimaNumber
                                            placeholder="{{'master.common.enter' | translate}} Transfer Quantity"
                                            (input)="calculateRemainingQuantity(j);validateWithDispatchQty(j);calculate1(pd25Form.value,j)"
                                            maxlength="13">
                                        <!-- (keypress)="checkLength($event.target.value,13)" -->
                                        <app-show-errors eleName="Transfer Quantity(BL)" [frmSub]="formSubmitted"
                                            [control]="getFormCntr1(j,'transferQuantityBl')">
                                        </app-show-errors>
                                    </td>
                                    <td [formGroupName]="j">
                                        <input type="text" class="form-control" formControlName="transferQuantityAl"
                                            appThreeDigitDecimaNumber
                                            placeholder="{{'master.common.enter' | translate}} Transfer Quantity(AL)"
                                            (keypress)="checkLength($event.target.value,13)" disabled>

                                    </td>
                                    <td [formGroupName]="j">
                                        <input type="text" class="form-control" formControlName="wastageBl"
                                            appThreeDigitDecimaNumber
                                            (input)="calculateWastageAL($event.target.value,j)"
                                            placeholder="{{'master.common.enter' | translate}} Wastage(BL)"
                                            (keypress)="checkLength($event.target.value,13)" maxlength="13">
                                        <app-show-errors eleName="Wastage(BL)" [frmSub]="formSubmitted"
                                            [control]="getFormCntr1(j,'wastageBl')">
                                        </app-show-errors>
                                    </td>
                                    <td [formGroupName]="j">
                                        <input type="text" class="form-control" formControlName="wastageAl"
                                            appThreeDigitDecimaNumber
                                            placeholder="{{'master.common.enter' | translate}} Wastage(AL)"
                                            (keypress)="checkLength($event.target.value,13)" maxlength="13" disabled>
                                    </td>
                                    <td [formGroupName]="j">
                                        <input type="text" class="form-control" formControlName="remainingQuantityBl"
                                            appThreeDigitDecimaNumber
                                            placeholder="{{'master.common.enter' | translate}} Remaining Quantity(BL)"
                                            (keypress)="checkLength($event.target.value,13)" disabled>
                                    </td>
                                    <td [formGroupName]="j">
                                        <input type="text" class="form-control" formControlName="remainingQuantityAl"
                                            appThreeDigitDecimaNumber
                                            placeholder="{{'master.common.enter' | translate}} Remaining Quantity(AL)"
                                            (keypress)="checkLength($event.target.value,13)" disabled>
                                    </td>
                                    <td class="table-action-column text-center">
                                        <button class="btn btn-icon btn-success" type="button"
                                            *ngIf="j==pd25Form.controls.transferDetails.length-1" (click)='addtank(j)'>
                                            <i class="feather icon-plus"></i></button>
                                        <button class="btn btn-icon btn-danger ml-2" type="button"
                                            (click)='deletetank(j)'
                                            *ngIf="pd25Form.controls.transferDetails.length > 1">
                                            <i class="fas fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </app-card>
            <app-card cardTitle="{{'potableDistillery.pd18id.transportationDetails' | translate }}"
                class="list-panel add-panel">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'potableDistillery.pd18id.transpoterName' | translate }}</label>
                            <input type="text" class="form-control" formControlName="transporterName"
                                placeholder=" {{'master.common.enter' | translate}} {{'potableDistillery.pd18id.transpoterName' | translate }}">
                            <app-show-errors eleName="{{'potableDistillery.pd18id.transpoterName' | translate }}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('transporterName')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory"> {{'potableDistillery.pd18id.vehicleNo' | translate }}</label>
                            <input type="text" class="form-control" formControlName="vehicleNo"
                                placeholder=" {{'master.common.enter' | translate}} {{'potableDistillery.pd18id.vehicleNo' | translate }}">
                            <app-show-errors eleName=" {{'potableDistillery.pd18id.vehicleNo' | translate }}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('vehicleNo')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'potableDistillery.pd18id.vehicleDriverName' | translate
                                }}</label>
                            <input type="text" class="form-control" formControlName="driverName"
                                placeholder=" {{'master.common.enter' | translate}} {{'potableDistillery.pd18id.vehicleDriverName' | translate}}">
                            <app-show-errors eleName="{{'potableDistillery.pd18id.vehicleDriverName' | translate }}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('driverName')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory"> {{'potableDistillery.pd18id.routeType' | translate }}</label>
                            <select class="form-control" formControlName="routeTypeCode"
                                (change)="changeRouteType($event.target.value)">
                                <option value="">{{'master.common.select' | translate}}</option>
                                <option *ngFor="let item of routeType" value="{{item.paramKey}}">{{item.paramValue}}
                                </option>
                            </select>
                            <app-show-errors eleName=" {{'potableDistillery.pd18id.routeType' | translate }}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('routeTypeCode')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'potableDistillery.pd18id.routeDetails' | translate }}</label>
                            <ng-multiselect-dropdown [data]="districtData" [placeholder]="'Select'"
                                formControlName="routeDetails" [(ngModel)]="pd26RoutDetails" [settings]="routeDetailsDropDownSettings"
                                [required]='routeDetailsRequired'
                                [ngClass]="routeDetailsDropDownSettings.singleSelection ? 'singleselect' : '' ">
                            </ng-multiselect-dropdown>
                            <app-show-errors eleName="{{'potableDistillery.pd18id.routeDetails' | translate }}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('routeDetails')">
                            </app-show-errors>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                        <div class="search-select">
                            <div class="form-group mb-0">
                                <label class="mandatory">{{'potableDistillery.pd18id.majorRoute' | translate }}</label>
                                <input type="text" class="form-control" formControlName="majorRoute"
                                    placeholder=" {{'master.common.enter' | translate}} {{'potableDistillery.pd18id.majorRoute' | translate }}">
                                <app-show-errors eleName="{{'potableDistillery.pd18id.majorRoute' | translate }}"
                                    [frmSub]="formSubmitted" [control]="getFormCntr('majorRoute')">
                                </app-show-errors>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory"> {{'potableDistillery.pd18id.distance' | translate }}</label>
                            <select class="form-control" formControlName="distance"
                                (change)=getRouteRange($event.target.value);validuptoValidation()>
                                <option value="">{{ 'sugarmill.common.select' | translate }}</option>
                                <option *ngFor="let item of routeRangeList" value="{{item.paramKey}}">
                                    {{item.paramValue}}</option>
                            </select>
                            <app-show-errors eleName="{{'potableDistillery.pd18id.distance' | translate }}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('distance')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">Valid Upto (hrs)</label>
                            <input type="text" class="form-control" (keypress)="numbersOnly($event)" maxlength="6"
                                appTwoDigitDecimaNumber formControlName="validUpToHrs" (change)="validuptoValidation()">
                        </div>
                        <app-show-errors eleName="{{'Valid Upto (Hrs)'}}" [frmSub]="formSubmitted"
                            [control]="getFormCntr('validUpToHrs')">
                        </app-show-errors>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group mono_calendar">
                            <label>{{ 'sugarmill.mf4transportpass.validupto' | translate }}</label>
                            &nbsp; <label class="error-msg">*</label>
                            <input type="text" class="form-control" formControlName="validUpTo"
                                [owlDateTimeTrigger]="dtPickerVUTDate" [owlDateTime]="dtPickerVUTDate" id="validUpTo"
                                onkeydown="event.preventDefault()" [owlDateTimeTrigger]="dtPickerVUTDate"
                                [owlDateTime]="dtPickerVUTDate" placeholder="DD/MM/YYYY HH:MM:SS" readonly>
                            <owl-date-time #dtPickerVUTDate disabled></owl-date-time>
                            <app-show-errors eleName="{{'potableDistillery.pd18id.validUpto' | translate}}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('validUpTo')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label>{{'potableDistillery.pd26fortankerid.temprature' | translate
                                }}</label>
                            <input type="text" (keypress)="checkLength($event.target.value,13)"
                                appThreeDigitDecimaNumber class="form-control" formControlName="temperature"
                                placeholder=" {{'master.common.enter' | translate}} {{'potableDistillery.pd26fortankerid.temprature' | translate}}">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label> {{'potableDistillery.pd26fortankerid.indication' | translate
                                }}</label>
                            <input type="text" (keypress)="checkLength($event.target.value,13)"
                                appThreeDigitDecimaNumber class="form-control" formControlName="indication"
                                placeholder=" {{'master.common.enter' | translate}} {{'potableDistillery.pd26fortankerid.indication' | translate}}">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory"> {{'potableDistillery.pd18id.strength' | translate }}(%V/V)</label>
                            <input type="text" (keypress)="checkLength($event.target.value,13)"
                                appThreeDigitDecimaNumber class="form-control" formControlName="strength"
                                placeholder=" {{'master.common.enter' | translate}} {{'potableDistillery.pd18id.strength' | translate }}">
                            <app-show-errors eleName="{{'potableDistillery.pd18id.strength' | translate }}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('strength')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label>{{'potableDistillery.pd26fortankerid.grossWeight' | translate
                                }}</label>
                            <input type="text" (keypress)="checkLength($event.target.value,13)"
                                appThreeDigitDecimaNumber class="form-control" formControlName="grossWeight"
                                (keyup)="checkQuantityRules($event, 'enteredGrossWeight')" (keyup)="calculateNet()"
                                placeholder=" {{'master.common.enter' | translate}} {{'potableDistillery.pd26fortankerid.grossWeight' | translate}}">
                            <app-show-errors eleName="{{'potableDistillery.pd26fortankerid.grossWeight' | translate }}"
                                [frmSub]="formSubmitted" [control]="getFormCntr('grossWeight')">
                            </app-show-errors>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label>{{'potableDistillery.pd26fortankerid.tareWeight' | translate
                                }}</label>
                            <input type="text" (keypress)="checkLength($event.target.value,13)"
                                appThreeDigitDecimaNumber class="form-control" formControlName="tareWeight"
                                (keyup)="checkQuantityRules($event, 'enteredTareWeight');calculateNet();getValidation($event.target.value)"
                                placeholder=" {{'master.common.enter' | translate}} {{'potableDistillery.pd26fortankerid.tareWeight' | translate }}">
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                            <label>{{'potableDistillery.pd26fortankerid.netWeight' | translate
                                }}</label>
                            <input type="text" readonly (keypress)="checkLength($event.target.value,13)"
                                class="form-control" formControlName="netWeight" appThreeDigitDecimaNumber
                                placeholder=" {{'master.common.enter' | translate}} {{'potableDistillery.pd26fortankerid.netWeight' | translate}}">
                        </div>
                    </div>
                    <div class="col-12 mb-2">

                        <div class="table-responsive">
                            <table class="table table-bordered text-center mb-0">
                                <thead>
                                    <tr>
                                        <th>{{'parentunit.intimationForm.se_no' | translate}}</th>
                                        <th class="mandatory">{{'potableDistillery.common.chemberNo' | translate}}</th>
                                        <th class="mandatory">{{'potableDistillery.common.dip' | translate}}(CMS)</th>
                                        <th class="mandatory">{{'potableDistillery.common.bl' | translate}}</th>
                                        <th class="mandatory"> {{'potableDistillery.common.al' | translate}}</th>
                                        <th> {{'master.common.action' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr formArrayName="chemberList"
                                        *ngFor="let pres of pd25Form.get('chemberList')['controls']; let j=index"
                                        id="row{{i}}">
                                        <td [formGroupName]="j">{{j+1}}</td>
                                        <td [formGroupName]="j">
                                            <input type="text" class="form-control"
                                                formControlName="chamberNo" appThreeDigitDecimaNumber
                                                placeholder="{{'master.common.enter' | translate}} {{'potableDistillery.common.chemberNo' | translate}}"
                                                (keypress)="checkLength($event.target.value,13)">
                                            <app-show-errors
                                                eleName="{{'potableDistillery.common.chemberNo' | translate }}"
                                                [frmSub]="formSubmitted"
                                                [control]="getFormCntr2(j,'chamberNo')">
                                            </app-show-errors>

                                        </td>
                                        <td [formGroupName]="j">
                                            <input type="text" class="form-control" formControlName="dip"
                                                appThreeDigitDecimaNumber
                                                placeholder="{{'master.common.enter' | translate}} {{'potableDistillery.common.dip' | translate}}"
                                                (keypress)="checkLength($event.target.value,13)">

                                            <app-show-errors eleName="{{'potableDistillery.common.dip' | translate }}"
                                                [frmSub]="formSubmitted" [control]="getFormCntr2(j,'dip')">
                                            </app-show-errors>

                                        </td>
                                        <td [formGroupName]="j">
                                            <input type="text" class="form-control" formControlName="quntityBl"
                                                appThreeDigitDecimaNumber
                                                placeholder="{{'master.common.enter' | translate}} {{'potableDistillery.common.bl' | translate}}"
                                                (keypress)="checkLength($event.target.value,13)" (keyup)="calculate(j)">

                                            <app-show-errors eleName="{{'potableDistillery.common.bl' | translate }}"
                                                [frmSub]="formSubmitted" [control]="getFormCntr2(j,'quntityBl')">
                                            </app-show-errors>


                                        </td>
                                        <td [formGroupName]="j">
                                            <input type="text" class="form-control" formControlName="quntityAL"
                                                appThreeDigitDecimaNumber
                                                placeholder="{{'master.common.enter' | translate}} {{'potableDistillery.common.al' | translate}}"
                                                (keypress)="checkLength($event.target.value,13)" disabled>

                                            <app-show-errors eleName="{{'potableDistillery.common.al' | translate }}"
                                                [frmSub]="formSubmitted" [control]="getFormCntr2(j,'quntityAL')">
                                            </app-show-errors>

                                        </td>
                                        <td class="table-action-column text-center">
                                            <button class="btn btn-icon btn-success" type="button"
                                                *ngIf="j==pd25Form.controls.chemberList.length-1"
                                                (click)='addlabelgroup(j)'>
                                                <i class="feather icon-plus"></i></button>
                                            <button class="btn btn-icon btn-danger ml-2" type="button"
                                                (click)='deletelabelrow(j)'
                                                *ngIf="pd25Form.controls.chemberList.length > 1">
                                                <i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </app-card>
            <!-- </div> -->
        
</form>
</div>
</div>
<div class="row">
    <div class="col-12 text-right mb-4">
        <button type="button" *ngIf="editObject?.status != 'SENDBACK'" class="btn btn-info mb-2"
            (click)="submitForm('DRAFT', true)">Preview</button>

            <button type="button" class="btn btn-warning ml-2 mb-2" (click)="submitForm('DRAFT')"
                *ngIf="editObject?.status != 'SENDBACK'">{{'master.common.saveasdraft'
                | translate }}</button>
        <button type="button" class="btn btn-primary ml-2 mb-2"
            (click)="submitForm('SUBMITTED')">{{'master.common.submit' |
            translate }}</button>
        <button type="button" class="btn btn-secondary ml-2 mb-2"
            routerLink="/{{url}}/nonregpd25/list">{{'master.common.cancel' |
            translate}}</button>
    </div>
</div>