<h5 class="m-b-10 ng-star-inserted">
        View Stock Reconcillation
</h5>
<ul class="breadcrumb p-0">
        <li class="breadcrumb-item"><a href="javascript:" [routerLink]="['/welcome']"
                        routerLinkActive="router-link-active"><i class="feather icon-home"></i></a></li>
        <li class="breadcrumb-item" *ngIf="entityType == 'PD'"><a>{{'otProductionPlan.list.production' |
                        translate}}</a></li>
        <li class="breadcrumb-item" *ngIf="entityType == 'ID'">
                <a>{{'otProductionPlan.list.industrialDistillery' |
                        translate}}</a>
        </li>
        <li class="breadcrumb-item" *ngIf="entityType == 'MIXED'"><a>Mixed
                        {{'otProductionPlan.list.distillery'| translate}} </a></li>
        <li class="breadcrumb-item" routerLink="/{{url}}/omd/omd-stock-in/list"><a href="javascript:">View Stock
                        Reconcillation</a></li>
        <li class="breadcrumb-item">View</li>
</ul>
<div class="row">
        <div class="col-12">
                <app-card cardTitle="Stock In OMD" class="list-panel add-panel">
                        <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">Date Of Application<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.createdDate | date:'dd/MM/yyyy'}}
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">Time Of Application<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.createdDate | date:'hh:mm a'}}
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                    <label class="w-100 view-label">Date and Time Of Arrival<span
                                                    class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.dateOfArrival | date: 'dd-MMM-yyyy hh:mm a'}}
                                </div>
                        </div>
                </app-card>
        </div>
        <div class="col-12">
                <app-card cardTitle="Unit Details" class="list-panel add-panel">
                        <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">OMD Registration No<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.licenseNumber}}
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">OMD Name<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.unitName}}
                                </div>
                                <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">OMD Address<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{licenseAddress}}
                                </div> -->
                        </div>
                </app-card>
        </div>
        <div class="col-12">
                <app-card cardTitle="Stock Activity Details" class="list-panel add-panel">
                        <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">Stock Activity<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.stockActivityTypeValue}}
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">Purchase Type<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.purchaseTypeValue}}
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">PD25 OMD Pass No<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.omdPd25PassNumber}}
                                </div>
                        </div>
                </app-card>
        </div>
        <div class="col-12">
                <app-card cardTitle="Consignor Details" class="list-panel add-panel">
                        <div class="row">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">Distillery Name<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.distilleryName}}
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">License Type<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.distilleryLicenseType}}
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">License No<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.distilleryLicenseNumber}}
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        <label class="w-100 view-label">Distillery Address<span
                                                        class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                        {{editObject?.distilleryAddress}}
                                </div>
                        </div>
                        <div class="col-12 mb-2 list-panel add-panel">
                                <div class="table-responsive setStyle">
                                    <table class="table table-bordered text-center mb-0">
                                        <thead>
                                            <tr>
                                                <th>{{'parentunit.intimationForm.se_no' | translate}}</th>
                                                <th class="mandatory">Raw Material Type</th>
                                                <th class="mandatory">Spirit Name</th>
                                                <th class="mandatory">Strength (%V/V)</th>
                                                <th class="mandatory">Issued Qty (in BL)</th>
                                                <th class="mandatory">Issued Qty (in AL)</th>
                                                <th class="mandatory">Received Qty (in BL)</th>
                                                <th class="mandatory">Received Qty (in AL)</th>
                                                <th class="mandatory">Wastage (in BL)</th>
                                                <th class="mandatory">Wastage (in AL)</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>{{editObject?.omdStockInRawMaterialDetails?.rawMaterialTypeValue}} </td>
                                                <td>{{editObject?.omdStockInRawMaterialDetails?.spiritNameValue}}</td>
                                                <td>{{editObject?.omdStockInRawMaterialDetails?.strength }}</td>
                                                <td>{{editObject?.omdStockInRawMaterialDetails?.issuedQuantityBl}} </td>
                                                <td>{{editObject?.omdStockInRawMaterialDetails?.issuedQuantityAl}} </td>
                                                <td>{{editObject?.omdStockInRawMaterialDetails?.receivedQuantityBl}} </td>
                                                <td>{{editObject?.omdStockInRawMaterialDetails?.receivedQuantityAl}} </td>
                                                <td>{{editObject?.omdStockInRawMaterialDetails?.wastageBl}} </td>
                                                <td>{{editObject?.omdStockInRawMaterialDetails?.wastageAl}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                <label class="w-100 view-label">Remarks<span
                                                class="float-right">:</span></label>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                                {{editObject?.remarks}}
                        </div>
                </app-card>
        </div>

        <div class="col-xl-12" *ngIf="logactive">
                <app-card cardTitle="Logs" blockClass="table-border-style" class="search-panel">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{'brewery.common.date' | translate }}</th>
                                    <th>{{'brewery.common.actionperformedby' | translate }}</th>
                                    <th>{{'brewery.common.designation' | translate }}</th>
                                    <th>{{'brewery.common.action' | translate }}</th>
                                    <th>{{'brewery.common.comments' | translate }}</th>
        
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let logs of logshow;let i=index'>
                                    <td>{{i+1}}</td>
                                    <td>{{logs?.actionTime | date:'dd/MM/yyyy HH:mm:ss' :"+0000"}}</td>
                                    <td>{{logs?.actionPerformedBy}}</td>
                                    <td>{{logs?.designation}}</td>
                                    <td>{{logs?.action}}</td>
                                    <td>{{logs?.comments}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        
            <div class="col-xl-12">
                <form [formGroup]="brandForm" utocomplete="off">
                    <app-card *ngIf="(editObject?.isShowButtons) && workflowdata?.isEditable" cardTitle="Remarks"
                        class="list-panel add-panel">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="mandatory">{{'brewery.common.remarks' | translate }}
                                    </label>
                                    <input type="text" class="form-control" placeholder="Enter Remarks"
                                        formControlName="remark">
                                    <div *ngIf="formSubmitted && (brandform.remark.errors || brandform.remark.dirty || brandform.remark.touched)"
                                        class="error-msg">
                                        <div *ngIf="brandform.remark?.errors?.required"><i class="fa fa-times"></i>
                                            Remarks is required </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </app-card>
        
        
                    <div class="col-5">
                        <div class="d-flex">
                            <div class="flex-fill">
                                <select class="form-control" formControlName="sendBackLevel"
                                    *ngIf='editObject?.isShowButtons && workflowdata?.isSendBack'>
                                    <option value="">{{'brewery.common.select' | translate }}</option>
                                    <option value="{{ sebd.key}}" *ngFor='let sebd of workflowdata?.sendBackList'>{{
                                        sebd.value}}
                                    </option>
                                </select>
                                <div *ngIf="formSubmitted && (brandform.sendBackLevel.errors || brandform.sendBackLevel.dirty || brandform.sendBackLevel.touched)"
                                    class="error-msg">
                                    <div *ngIf="brandform.sendBackLevel?.errors?.required"><i class="fa fa-times"></i>
                                        Dropdown Value Is required </div>
                                </div>
                            </div>
                            <div class="flex-fill">
                                <button type="button" class="btn btn-warning ml-2 mb-2"
                                    (click)="showConfirmation('SENDBACK', brandForm)" [disabled]="isClarificationLoading"
                                    *ngIf="(editObject?.isShowButtons) && workflowdata?.isSendBack">
                                    <i class="fa"
                                        [ngClass]="{'fa-spin fa-spinner': isClarificationLoading, 'fa-check': !isClarificationLoading}"></i>
                                    Request for Clarification
                                </button>
                            </div>
                        </div>
                    </div>
        
        
        
                </form>
            </div>
            <!-- <div class="col-12 text-right mb-4" *ngIf="!logactive">
                <button type="button" class="btn btn-secondary ml-2 mb-2" routerLink="/{{url}}/omd/omd-stock-in/list">{{
                    'brewery.common.back' | translate }}</button>
            </div> -->
            <div class="col-12 text-right mb-4">
                <button type="submit" class="btn btn-success mb-2 ml-2" (click)="captureScreen()">
                    {{ 'language.download' | translate }}</button>
                <!-- <button type="button" *ngIf="!isPreview" class="btn btn-secondary ml-2 mb-2"
                    routerLink="/{{url}}/omd/omd-stock-in/list">{{'language.back'|translate}}</button> -->
                <button type="button" class="btn btn-secondary ml-2 mb-2" routerLink="/{{url}}/omd/omd-stock-in/list">{{
                    'brewery.common.back' | translate }}</button>
                <button type="button" class="btn btn-success mb-2 ml-2" (click)="showConfirmation('APPROVED', brandForm)"
                    [disabled]="isApprovedLoading" *ngIf="(editObject?.isShowButtons) && workflowdata?.isEditable">
                    <i class="fa" [ngClass]="{'fa-spin fa-spinner': isApprovedLoading, 'fa-check': !isApprovedLoading}"></i>
                    {{ 'brewery.common.approve' | translate }}</button>
                <button type="button" class="btn btn-danger mb-2 ml-2" (click)="showConfirmation('REJECTED', brandForm)"
                    [disabled]="isRejectLoading" *ngIf="(editObject?.isShowButtons) && workflowdata?.isRejected">
                    <i class="fa" [ngClass]="{'fa-spin fa-spinner': isRejectLoading, 'fa-check': !isRejectLoading}"></i>
                    {{ 'brewery.common.reject' | translate }}</button>
        
        
            </div>
</div>

<app-ui-modal #exampleModalCenter dialogClass="modal-dialog-centered" (click)="close()">
    <div class="app-modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="close()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <h6 class="text-secondary text-center" *ngIf="confirmStyatus == 'APPROVED'">
            Are you sure you want to Approve?
        </h6>
        <h6 class="text-secondary text-center" *ngIf="confirmStyatus == 'REJECTED'">
            Are you sure you want to Reject?
        </h6>
        <h6 class="text-secondary text-center" *ngIf="confirmStyatus == 'SENDBACK'">
            Are you sure you want to Request for Clarification?
        </h6>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" (click)="feedback(confirmStyatus)">Yes</button>
        <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal" (click)="close()">No</button>
    </div>
</app-ui-modal>