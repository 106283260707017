<h5 class="m-b-10 ng-star-inserted">{{'master.common.view' | translate}} Dispatch Of Spirit to Non Register User PD 25
</h5>
<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:" [routerLink]="['/welcome']"><i class="feather icon-home"></i></a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'PD'"><a>{{'otProductionPlan.list.production' |
            translate}}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'ID'"><a>{{'otProductionPlan.list.industrialDistillery' |
            translate}}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'MIXED'"><a>Mixed
            {{'otProductionPlan.list.distillery'| translate}} </a></li>
    <li class="breadcrumb-item"><a href="javascript:" routerLink="/{{url}}/nonregpd25/list">Dispatch Of Spirit to Non Register User PD 25</a></li>

    <li class="breadcrumb-item"><a>{{'master.common.view' | translate}}</a></li>
</ul>
<div class="row">
    <div class="col-12">
        <form [formGroup]="pd25Form" autocomplete="off" id="contentToConvert">
            <app-card cardTitle="Dispatch Details" class="list-panel add-panel">
                <div class="row">
                    
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'molassesDC.list.appn' | translate }}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <span>{{editObject?.applicationNumber}}</span>
                    </div>
                    <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Generating For<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.consigneeEntityType}}
                    </div> -->
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Date<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.createdDate | date:'dd/MM/yyyy'}}
                    </div>
                </div>
            </app-card>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <app-card cardTitle="Consignor Details" class="list-panel add-panel">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">{{'molassesDC.list.dName' | translate}}<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{editObject?.unitName}}
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">{{'molassesDC.list.lType' | translate}}<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{editObject?.licenseType}}
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">{{'molassesDC.list.licenseNumber' | translate}}<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{editObject?.licenseNumber}}
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">{{'molassesDC.add.dAddr' | translate}}<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{editObject?.consignorAddress}}
                                </div>
                            </div>
                        </app-card>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <app-card cardTitle="{{'blendTransferReq.add.consigneeDetails' | translate}}"
                            class="list-panel add-panel">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">Entity Type
                                        <span class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{editObject?.consigneeEntityType}}
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">Entity Name<span
                                            class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{editObject?.consigneeEntityName}}
                                </div>
                                <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">License No<span class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{editObject?.omdLicenseNo}}
                                </div> -->
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">Entity Registration No<span class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{editObject?.consigneeRegNo}}
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <label class="w-100 view-label">Entity PAN No<span class="float-right">:</span></label>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    {{editObject?.consigneePanNo}}
                                </div>
                            </div>
                        </app-card>
                    </div>
                </div>
            </div>
            <div class="col-xl-12">
                <app-card cardTitle="Permit Details" class="list-panel add-panel">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="w-100 view-label">Permit No<span class="float-right">:</span></label>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            {{editObject?.permitNo}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="w-100 view-label">
                                Permit Date
                                <span class="float-right">:</span></label>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            {{editObject?.permitDate | date:'dd/MM/yyyy'}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="w-100 view-label">
                                Sprit Type
                                <span class="float-right">:</span></label>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            {{editObject?.spiritType}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="w-100 view-label">
                                Permitted Quantity
                                <span class="float-right">:</span></label>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            {{editObject?.permitQty}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="w-100 view-label">
                               Issued Quantity
                                <span class="float-right">:</span></label>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            {{editObject?.issueQty}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            <label class="w-100 view-label">
                               Advolaram Fees
                                <span class="float-right">:</span></label>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                            {{editObject?.advolaramFee}}
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <label class="w-100 view-label">Upload Cleaning Document<span class="float-right">:</span></label>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <span>{{uploaddata?.documentName}} <button class="btn btn-icon btn-success mr-2 mb-2" type="button"
                                (click)="downloadDocument(uploaddata.uuid,uploaddata.documentName)"> <i
                                class="feather icon-download" ></i>
                            </button>
                            <button class="btn btn-icon btn-primary " type="button"
                            (click)="DownloadDoc(uploaddata.uuid,uploaddata.documentName)">
                            <i class="feather icon-eye"></i></button></span>
                        </div>
                    </div>
                </app-card>
            </div>
            <app-card cardTitle="Tank Details" class="list-panel view-panel">
                <div class="col-sm-12">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr>
                                    <th>{{'parentunit.intimationForm.se_no' | translate}}</th>
                                    <th> <label>Tank Name</label> </th>
                                    <th><label>Strength(%v/v)</label> </th>
                                    <th><label>Available Quantity(BL)</label> </th>
                                    <th><label>Available Quantity(AL)</label> </th>
                                    <th>Transfer Quantity(BL)</th>
                                    <th>Transfer Quantity(AL)</th>
                                    <th>Wastage(BL)</th>
                                    <th>Wastage(AL)</th>
                                    <th>Remaining Quantity(BL)</th>
                                    <th>Remaining Quantity(AL)</th>
                                </tr>
                            </thead>

                            <tbody *ngIf="editObject?.nonRegisterTankDetailsList">
                                <tr *ngFor='let val of editObject?.nonRegisterTankDetailsList;let i=index'>
                                    <td>{{i + 1}}</td>
                                    <td>{{val.tankName}}({{val.tankId}}) </td>
                                    <td>{{val.strenth}}</td>
                                    <td>{{val.availableQtyBl}}</td>
                                    <td>{{val.availableQtyAl}}</td>
                                    <td>{{val.transferQtyBl}}</td>
                                    <td>{{val.transferQtyAl}}</td>
                                    <td>{{val.wastageBl}}</td>
                                    <td>{{val.wastageAl}}</td>
                                    <td>{{val.remainingQtyBl}}</td>
                                    <td>{{val.remainingQtyAl}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </app-card>
            <app-card cardTitle="{{'potableDistillery.pd26fortankerid.transportationDetails' | translate }}"
                class="list-panel view-panel">
                <div class="row">
                    <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <label class="w-100 view-label">{{'potableDistillery.pd18id.modeofTransfer' | translate }}<span
                            class="float-right">:</span></label>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    {{editObject?.modeOfTransfer}}
                </div> -->
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'potableDistillery.pd18id.transpoterName' | translate
                            }}<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.transporterName}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'potableDistillery.pd18id.vehicleNo' | translate }}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.vehicleNo}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'potableDistillery.pd18id.vehicleDriverName' | translate
                            }}<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.driverName}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label"> {{'potableDistillery.pd18id.routeType' | translate }}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.routeTypeValue}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'potableDistillery.pd18id.routeDetails' | translate
                            }}<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <span *ngFor="let item of editObject?.nonRegisterPD25RouteDetailsList">{{item.routeTypeValue}} </span>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'potableDistillery.pd18id.majorRoute' | translate }}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.majorRoute}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'potableDistillery.pd18id.distance' | translate }}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.distance}}
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">Valid Upto<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <!-- <span>{{modelData?.validUpto | date:'dd/MM/yyyy'}}</span> -->
                            <input type="text" class="form-control" formControlName="validUpTo"
                                
                                    [owlDateTimeTrigger]="dtPickerVUTDate" [owlDateTime]="dtPickerVUTDate"
                                    id="validUpto" onkeydown="event.preventDefault()" [owlDateTimeTrigger]="dtPickerVUTDate"
                                    [owlDateTime]="dtPickerVUTDate" 
                                    placeholder="DD/MM/YYYY HH:MM:SS" readonly>
                                <owl-date-time #dtPickerVUTDate disabled></owl-date-time>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{ 'sugarmill.mf4transportpass.validupto' | translate }} (hrs)
                            <span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <input type="text" class="form-control" maxlength="6" (keypress)="numbersOnly($event)" appTwoDigitDecimaNumber formControlName="validUpToHrs" (change)="validuptoValidation()">
                     </div>
                    
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'potableDistillery.pd26fortankerid.temprature' | translate
                            }}<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.temperature}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label"> {{'potableDistillery.pd26fortankerid.indication' |
                            translate
                            }}<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.indication}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label"> {{'potableDistillery.pd18id.strength' | translate }}<span
                                class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.strength}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'potableDistillery.pd26fortankerid.grossWeight' |
                            translate
                            }}<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.grossWeight}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'potableDistillery.pd26fortankerid.tareWeight' | translate
                            }}<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.tareWeight}}
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        <label class="w-100 view-label">{{'potableDistillery.pd26fortankerid.netWeight' | translate
                            }}<span class="float-right">:</span></label>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                        {{editObject?.netWeight}}
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center mb-0">
                            <thead>
                                <tr>
                                    <th>{{'parentunit.intimationForm.se_no' | translate}}</th>
                                    <th>{{'potableDistillery.common.chemberNo' | translate}}</th>
                                    <th>{{'potableDistillery.common.dip' | translate}}</th>
                                    <th>{{'potableDistillery.common.bl' | translate}}</th>
                                    <th> {{'potableDistillery.common.al' | translate}}</th>
                                    <!-- <th>Status</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of editObject?.nonRegisterPD25ChamberDetailsList; let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{item.chamberNo}}</td>
                                    <td class="text-left">{{item.dip}}</td>
                                    <td class="text-left">{{item.quntityBl}}</td>
                                    <td class="text-left">{{item.quntityAL}}</td>
                                    <!-- <td class="text-center"><span class="badge badge-light-success">Approved</span></td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </app-card>

            <app-card *ngIf="logactive" cardTitle="Logs" blockClass="table-border-style" class="search-panel">
                <div class="table-responsive">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr>
                                <th>{{'parentunit.intimationForm.se_no' | translate }}</th>
                                <th>{{'brewery.common.date' | translate }}</th>
                                <th>{{'brewery.common.actionperformedby' | translate }}</th>
                                <th>{{'brewery.common.designation' | translate }}</th>
                                <th>{{'brewery.common.action' | translate }}</th>
                                <th>{{'brewery.common.comments' | translate }}</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let logs of logshow;let i=index'>
                                <td>{{i+1}}</td>
                                <td>{{logs?.actionTime | date:"dd/MM/yyyy HH:mm:ss":"+0000"}}</td>
                                <td>{{logs?.actionPerformedBy}}</td>
                                <td>{{logs?.designation}}</td>
                                <td>{{logs?.action}}</td>
                                <td>{{logs?.comments}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </app-card>
            <app-card *ngIf="(editObject?.isShowButtons) && workflowdata?.isEditable" cardTitle="Remarks"
                class="list-panel add-panel">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="mandatory">{{'brewery.common.remarks' | translate }}
                            </label>
                            <input type="text" class="form-control" placeholder="Enter Remarks"
                                formControlName="remark">
                            <div *ngIf="formSubmitted && (brandform.remark.errors || brandform.remark.dirty || brandform.remark.touched)"
                                class="error-msg">

                                <span *ngIf="formSubmitted && brandform.remark?.errors?.required">
                                    {{'brewery.common.remarks' | translate }} {{'master.common.isrequired' | translate}}
                                    <br>
                                </span>
                                <span *ngIf="brandform.remark?.errors?.maxlength">
                                    {{'brewery.common.remarks' | translate }} must be less than 100 characters
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>


            <div class="col-5">
                <div class="d-flex">
                    <div class="flex-fill">
                        <select class="form-control" formControlName="sendBackLevel"
                            *ngIf='editObject?.isShowButtons && workflowdata?.isSendBack'>
                            <option value="">{{'brewery.common.select' | translate }}</option>
                            <option value="{{ sebd.key}}" *ngFor='let sebd of workflowdata?.sendBackList'>{{
                                sebd.value}}
                            </option>
                        </select>
                        <div *ngIf="formSubmitted && (brandform.sendBackLevel.errors || brandform.sendBackLevel.dirty || brandform.sendBackLevel.touched)"
                            class="error-msg">
                            <div *ngIf="brandform.sendBackLevel?.errors?.required"><i class="fa fa-times"></i>
                                Dropdown Value Is required </div>
                        </div>
                    </div>
                    <div class="flex-fill">
                        <button type="button" class="btn btn-warning ml-2 mb-2"
                            (click)="showConfirmation('SENDBACK', pd25Form)" [disabled]="isClarificationLoading"
                            *ngIf="(editObject?.isShowButtons) && workflowdata?.isSendBack">
                            <i class="fa"
                                [ngClass]="{'fa-spin fa-spinner': isClarificationLoading, 'fa-check': !isClarificationLoading}"></i>
                            Request for Clarification
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="col-12 text-right mb-4">
        <button type="button" class="btn btn-success ml-2" (click)="showConfirmation('APPROVED', pd25Form)"
            [disabled]="isApprovedLoading" *ngIf="(editObject?.isShowButtons) && workflowdata?.isEditable">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isApprovedLoading, 'fa-check': !isApprovedLoading}"></i>
            {{ 'brewery.common.approve' | translate }}</button>
        <button type="button" class="btn btn-danger ml-2" (click)="showConfirmation('REJECTED', pd25Form)"
            [disabled]="isRejectLoading" *ngIf="(editObject?.isShowButtons) && workflowdata?.isRejected">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner': isRejectLoading, 'fa-check': !isRejectLoading}"></i>
            {{ 'brewery.common.reject' | translate }}</button>

        <button type="submit" class="btn btn-success  ml-2" (click)="captureScreen()"
            *ngIf="editObject?.status == 'APPROVED'">
            {{ 'language.download' | translate }}</button>
            <button type="submit" class="btn btn-success  ml-2" (click)="paymentReceipt()"
            *ngIf="editObject?.status == 'APPROVED'">
            Payment Receipt</button>

        <button *ngIf="!isPreview" type="button" routerLink="/{{url}}/nonregpd25/list"
            class="btn btn-secondary ml-2">{{'potableDistillery.common.back' | translate}}</button>

        <button *ngIf="isPreview" type="button" class="btn btn-secondary ml-2 mb-2"
            (click)="closes()">{{'brewery.common.back'|translate}}</button>

    </div>
</div>
<app-ui-modal #exampleModalCenter dialogClass="modal-dialog-centered" (click)="close()">
    <div class="app-modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="close()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <h6 class="text-secondary text-center" *ngIf="confirmStatus == 'APPROVED'">
            Are you sure you want to Approve?
        </h6>
        <h6 class="text-secondary text-center" *ngIf="confirmStatus == 'REJECTED'">
            Are you sure you want to Reject?
        </h6>
        <h6 class="text-secondary text-center" *ngIf="confirmStatus == 'SENDBACK'">
            Are you sure you want to Request for Clarification?
        </h6>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" (click)="feedback(confirmStatus)">Yes</button>
        <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal" (click)="close()">No</button>
    </div>
</app-ui-modal>
<app-ui-modal #exampleModalCenter1 dialogClass="modal-dialog-centered   modal-xl">
    <div class="app-modal-body text-center" style="padding-top: 20px;">
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="exampleModalCenter1.hide()"><span aria-hidden="true">&times;</span></button>
        <img *ngIf="!imageboolean" src="data:image/jpeg;base64,{{imageSrcPath}}"
            style="width:100%; object-fit: contain;height:600px" />
        <div *ngIf="imageboolean === true">
            <iframe style="width:100%;height:600px;" target="_blank" frameborder="no"></iframe>
        </div>
        <h3>{{imageDocName}}</h3>
    </div>
</app-ui-modal>