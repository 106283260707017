import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'

@Injectable({
  providedIn: 'root'
})
export class TanktransferService {

  // baseURL = environment.sugarmillBaseURL;
  baseURL = environment.scmproduction;
  constructor(private http: HttpClient) { }
  getAllTanReg(): Observable<any> {
    const url = this.baseURL + apiUrls.getAllTankReg;
    return this.http.get(url);
  }
  getTanRegById(id): Observable<any> {
    const url = this.baseURL + apiUrls.tankRegGetById + id;
    return this.http.get(url);
  }
  addTantransfer(postdata): Observable<any> {
    console.log(postdata)
    const url = this.baseURL + apiUrls.addtanktransfer;
    return this.http.post(url, postdata);
  }
  approveTanReg(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.trankRegApprove;
    return this.http.post(url, postdata);
  }
  updateTanReg(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.tankRegUpdate;
    return this.http.put(url, postdata);
  }
  searchTanReg(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.searchTankReg;
    return this.http.post(url, postdata);
  }
  getDownload(): Observable<any> {
    const url = this.baseURL + apiUrls.allotmentexportExcel;
    return this.http.get(url, { responseType: 'blob' });
  }
  getPrintData(urls: any): Observable<any> {
    const url = this.baseURL + urls;
    return this.http.get(url, { responseType: 'blob' });
  }


  addTankTransferDetails(data): Observable<any> {
    const url = this.baseURL + 'tankTransfer';
    return this.http.post(url, data);
  }

  tankTransferSearch(data): Observable<any> {
    const url = this.baseURL + 'tankTransfer/lazySearch';
    // const url = 'http://localhost:3501/scmProduction/tankTransfer/lazySearch';
    return this.http.post(url, data);
  }
  hideGinTransfer(): Observable<any>{
    const url = this.baseURL + 'tankTransfer/getUserId?entityType=PD'
    return this.http.get(url);
  }

  tankTransferSearch1(data): Observable<any> {
    const url = this.baseURL + 'tankTransfer/subTransfer/lazySearch';
    return this.http.post(url, data);
  }
  updateWorkFlow(payload): Observable<any> {
    const url = this.baseURL + 'tankTransfer/updateWorkFlow';
    return this.http.post(url, payload);
  }

  getById(id): Observable<any> {
    const url = this.baseURL + 'tankTransfer/' + id;
    return this.http.get(url);
  }
  getStatusCount(Payload): Observable<any> {
    const url = this.baseURL + 'tankTransfer/getStatusCount?'
    return this.http.post(url,Payload);
  }
  getStatusCountPD(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    const url = this.baseURL + 'tankTransfer/getStatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords ;
    return this.http.get(url);
  }
  getSugarMillCardCount(payload): Observable<any> {
    const url = this.baseURL + 'sugarMill/getStatusCount'
    return this.http.post(url,payload);
  }

}
