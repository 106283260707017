<h5 class="m-b-10 ng-star-inserted">
     Dispatch Of Spirit to Non Register User PD 25
    <button type="submit" class="btn btn-success custom_add_button pull-right" *ngIf="isCustomer"
        routerLink="/{{url}}/nonregpd25/add"><i class="feather icon-plus mr-2"></i>
        Add Dispatch Of Spirit to Non Register User PD 25
    </button>
</h5>
<ul class="breadcrumb p-0">
    <li class="breadcrumb-item"><a href="javascript:" [routerLink]="['/welcome']"><i class="feather icon-home"></i></a>
    </li>
    <li class="breadcrumb-item" *ngIf="entityType == 'PD'"><a href="javascript:">
            {{ 'language.pd' | translate }}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'ID'"><a href="javascript:">
            {{ 'language.industrialDistillery' | translate }}</a></li>
    <li class="breadcrumb-item" *ngIf="entityType == 'MIXED'"><a>Mixed
            {{'otProductionPlan.list.distillery'| translate}} </a></li>
    <li class="breadcrumb-item"><a [routerLink]="['/' + url + '/nonregpd25/list']">
        Dispatch Of Spirit to Non Register User PD 25</a></li>
    <li aria-current="page" class="breadcrumb-item active">{{ 'language.list' | translate }}</li>
</ul>

<!-- <div class="row">
    <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg4 animate__animated animate__backInUp">
        <app-card [hidHeader]="true" cardClass="comp-card linkClass"
            (click)="changeStatus('normal');getList('APPROVED')">
            <div class="row align-items-center">
                <div class="col">
                    <span>{{ 'brewery.brandlabelreglist.brandapproved' | translate }}</span>
                    <h3 class="mt-2 strong">{{dataCount?.totalApprovedApplications}}</h3>
                    <i class="fas fa-thumbs-up"></i>
                </div>
            </div>
        </app-card>
    </div>

    <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg1 animate__animated animate__backInUp" *ngIf="iscustomer">
        <app-card [hidHeader]="true" cardClass="comp-card linkClass"
            (click)="changeStatus('current');getList('IN_PROGRESS')">
            <div class="row align-items-center">
                <div class="col">
                    <span>{{ 'brewery.brandlabelreglist.brandreqpending' | translate }}</span>
                    <h3 class="mt-2 strong">{{dataCount?.totalPendingApplications}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                </div>
            </div>
        </app-card>
    </div>

    <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg1 animate__animated animate__backInUp" *ngIf="!iscustomer">
        <app-card [hidHeader]="true" cardClass="comp-card linkClass"
            (click)="changeStatus('current');getList('IN_PROGRESS')">
            <div class="row align-items-center">
                <div class="col">
                    <span>{{ 'brewery.brandlabelreglist.brandreqpending' | translate }} (Current
                        User)</span>
                    <h3 class="mt-2 strong">{{dataCount?.totalPendingApplicationsCurrentOfficer}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                </div>
            </div>
        </app-card>
    </div>

    <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg5 animate__animated animate__backInUp" *ngIf="!iscustomer">
        <app-card [hidHeader]="true" cardClass="comp-card linkClass"
            (click)="changeStatus('Officer');getList('IN_PROGRESS')">
            <div class="row align-items-center">
                <div class="col">
                    <span style="position: absolute;width: 100%;">{{ 'brewery.brandlabelreglist.brandreqpending' |
                        translate }}
                        (All Other Officer)</span><br>
                    <h3 class="mt-2 strong">{{dataCount?.totalPendingApplicationsOtherOfficer}}</h3>
                    <i class="fas fa-hourglass-half"></i>
                </div>
            </div>
        </app-card>
    </div>

    <div class="col-md-6 col-xl-3 col-sm-6 dashboard_bg3 animate__animated animate__backInUp">
        <app-card [hidHeader]="true" cardClass="comp-card bg-danger linkClass"
            (click)="changeStatus('normal');getList('REJECTED')">
            <div class="row align-items-center">
                <div class="col">
                    <span>{{ 'brewery.brandlabelreglist.brandreqrejected' | translate }}</span>
                    <h3 class="mt-2 strong">{{dataCount?.totalRejectedApplications}}</h3>
                    <i class="fas fa-thumbs-down text-danger"></i>
                </div>
            </div>
        </app-card>
    </div>
</div> -->

<app-card cardTitle="Search" class="search-panel">
    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="form-group">
              <label>Date</label>
              <input [owlDateTime]="ApplicationDate" required [(ngModel)]="Obj['date']" class="form-control"
                [owlDateTimeTrigger]="ApplicationDate" readonly="true"
                placeholder="{{ 'language.enter' | translate }} {{ 'brewery.brandlabelreglist.appdate' | translate }}">
              <owl-date-time [pickerType]="'calendar'" #ApplicationDate></owl-date-time>
  
            </div>
          </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
            <div class="form-group">
                <label>Application No</label>
                <input type="text" class="form-control" placeholder="Enter Application No No"
                    [(ngModel)]="Obj ['application'] ">
            </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
            <div class="form-group">
                <label>Status</label>
                <select id="status" required class="form-control" [(ngModel)]="Obj['status']">
                    <option value="">{{'parentunit.common.select' | translate}}</option>
                    <option *ngFor="let item of statuslist" value="{{item.name}}">{{item.value}}</option>
                  </select>
            </div>
        </div>
        <div class="col-12 text-right">
            <div>
                <button type="submit" class="btn btn-primary mr-2" (click)="getList()">Search</button>
                <button type="submit" class="btn btn-secondary" (click)="clearData()">Clear</button>
            </div>
        </div>
    </div>
</app-card>


<div class="row">
    <div class="col-xl-12">
        <app-card cardTitle="Dispatch Of Spirit to Non Register User PD 25 List" blockClass="table-border-style" class="list-panel">
            <div class="table-responsive mb-2">
                <app-mat-normal-table [columns]="columns" [actionBtnBasedOnCond]="actionBtnBasedOnCond"
                    [actionKeys]="actionKeys" [dataSource]="dataSource" [pageSize]="pageSize" [totalCount]="totalCount"
                    (paginationEvent)="handlePagination($event)" (editAction)="onEdit($event)"
                    (viewAction)="onView($event)">
                </app-mat-normal-table>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div ngbDropdown>
                        <button class="btn btn-success" ngbDropdownToggle type="button">
                            {{ 'language.download' | translate }}</button>
                        <div ngbDropdownMenu>
                            <a class="dropdown-item" (click)="ExportTOExcel()" href="javascript:">
                                {{ 'language.excel' | translate }}</a>
                            <a class="dropdown-item" (click)="generatePdf()" href="javascript:">
                                {{ 'language.pdf' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </app-card>
    </div>